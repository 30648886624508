/* global ENV */
import * as Sentry from '@sentry/react';
import { Alert } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { t } from 'ttag';
import logger from 'use-reducer-logger';

import Loading from 'common/components/Loading';
import useSearchParams from 'common/hooks/useSearchParams';

import { BADGES } from '@domains/Capture/constants';

import Builder from './Builder';
import {
  COLUMN_SIZE,
  UNIT_BLEED_LENGTH,
  UNIT_CROP_MARK_LENGTH,
  UNIT_DIVISION,
} from './Builder/constants';
import { BadgeTypes, UNIT_INCH, UNIT_MM } from './constants';
import { transform as transformFn } from './designer-service';
import Grid, { getGridProps } from './Download/BadgeGrid';
import { Page, CropMarksContainer, CropMarks } from './Download/PagePreview';
import { list as listPeopleFn, getPerson as getPersonFn } from './people-service';
import reducer, { ACTION_POPULATE, ACTION_SET_LOADING, initialState } from './reducer';
import { get as getBadgeFn } from './service';

const NOT_FOUND_ERROR_CODES = ['badges_not_found'];

const MOCK_PERON_DATA_FOR_TESTING = {
  id: '70d7436c-395e-4363-b76d-0abef63dfad1',
  externalId: 'fd093a77-15da-4865-945c-0349f70f64a8',
  email: 'johndoe@eventmobi.com',
  firstName: 'John',
  lastName: 'Doe',
  companyName: 'EventMobi',
  title: 'Software Engineer',
  checkinCode: '70d7436c-395e-4363-b76d-0abef63dfad1',
  customFields: [],
};

const DEFAULT_SIMPLE_LABEL_CONFIG = {
  layout_unit: UNIT_MM,
  layout_spacing_column: '0',
  layout_spacing_row: '0',
  layout_margins_top: '0',
  layout_margins_right: '0',
  layout_margins_bottom: '0',
  layout_margins_left: '0',
  sort: 'first_name',
  fill: 'across',
  border: 'false',
  crop_marks: 'false',
};

const PageStyle = createGlobalStyle`
  // @page tells playwright what size the actual page is
  // it relies on 'prefer_css_page_size' param to be true in
  // https://playwright.dev/python/docs/api/class-page#page-pdf-option-prefer-css-page-size
  //
  // it also tells page size to the print dialog in browser
  // when you press cmd+p / ctrl+p
  @page {
    size: ${props => props.width * props.scale}px ${props => props.height * props.scale}px;
  }

  ${Page} {
    // in some cases we need to rescale the page as correction measure
    //
    // we used 'zoom', instead of 'transform: scale()'
    // 'transform: scale()' only scales the content inside it, while
    // occupying the original size of block on page
    // while 'zoom' scales both.
    //
    // IMPORTANT: 'zoom' is a non-standard css feature
    // https://developer.mozilla.org/en-US/docs/Web/CSS/zoom
    zoom: ${props => props.scale};
  }
`;

const roundFloat = v => parseFloat(v.toFixed(1));
const normalizeUnit = (v, unit) =>
  (unit === UNIT_INCH ? roundFloat(v * UNIT_DIVISION[UNIT_INCH]) : v) * COLUMN_SIZE;

const getNextBadges = ({ page, gridProps, badges }) => {
  const { rows, cols } = gridProps;
  const offset = page * rows * cols;
  const sortedBadges = [...badges].slice(offset, offset + rows * cols);
  return sortedBadges;
};

const parseConfig = (query, badge) => {
  const paramKeys = [
    'page_size_width',
    'page_size_height',
    'page_size_unit',
    'layout_unit',
    'layout_margins_top',
    'layout_margins_right',
    'layout_margins_bottom',
    'layout_margins_left',
    'layout_spacing_column',
    'layout_spacing_row',
    'sort',
    'fill',
    'border',
    'crop_marks',
  ];

  let initialConfig = Object.fromEntries(query.entries());

  if (badge.badgeType === BadgeTypes.SIMPLE_LABEL) {
    initialConfig = { ...initialConfig, ...DEFAULT_SIMPLE_LABEL_CONFIG };
  }

  const missingParamKeys = paramKeys.filter(key => !initialConfig[key]);
  if (missingParamKeys.length > 0) {
    const list = missingParamKeys.map(key => `'${key}'`);
    throw new Error(`${list.join(', ')} query param is/are required.`);
  }

  const rawConfig = {
    pageSize: {
      width: parseFloat(initialConfig.page_size_width),
      height: parseFloat(initialConfig.page_size_height),
      unit: initialConfig.page_size_unit,
    },
    layout: {
      unit: initialConfig.layout_unit,
      margins: {
        top: parseFloat(initialConfig.layout_margins_top),
        right: parseFloat(initialConfig.layout_margins_right),
        bottom: parseFloat(initialConfig.layout_margins_bottom),
        left: parseFloat(initialConfig.layout_margins_left),
      },
      spacing: {
        column: parseFloat(initialConfig.layout_spacing_column),
        row: parseFloat(initialConfig.layout_spacing_row),
      },
    },
    sort: initialConfig.sort,
    fill: initialConfig.fill,
    border: initialConfig.border.toLowerCase() === 'true',
    cropMarks: initialConfig.crop_marks.toLowerCase() === 'true',
    scale: parseFloat(initialConfig.scale ?? 1),
  };

  const pageSizeUnit = rawConfig.pageSize.unit;
  const layoutUnit = rawConfig.layout.unit;
  const badgeSizeUnit = badge.size.unitType;

  const config = {
    renderOptions: {
      gridProps: {
        /* to be filled later */
      },
    },
    pageSize: {
      width: normalizeUnit(rawConfig.pageSize.width, pageSizeUnit),
      height: normalizeUnit(rawConfig.pageSize.height, pageSizeUnit),
      unit: pageSizeUnit,
    },
    margins: {
      top: normalizeUnit(rawConfig.layout.margins.top, layoutUnit),
      right: normalizeUnit(rawConfig.layout.margins.right, layoutUnit),
      bottom: normalizeUnit(rawConfig.layout.margins.bottom, layoutUnit),
      left: normalizeUnit(rawConfig.layout.margins.left, layoutUnit),
    },
    spacing: {
      column: normalizeUnit(rawConfig.layout.spacing.column, layoutUnit),
      row: normalizeUnit(rawConfig.layout.spacing.row, layoutUnit),
    },
    badgeSize: {
      width: normalizeUnit(parseFloat(badge.size.width), badgeSizeUnit),
      height: normalizeUnit(parseFloat(badge.size.height), badgeSizeUnit),
    },
    sort: rawConfig.sort,
    fill: rawConfig.fill,
    border: rawConfig.border,
    cropMarks: rawConfig.cropMarks,
    scale: rawConfig.scale,
  };

  config.renderOptions.gridProps = getGridProps(
    config.pageSize.width - config.margins.left - config.margins.right,
    config.pageSize.height - config.margins.top - config.margins.bottom,
    config.spacing,
    config.badgeSize
  );

  return config;
};

// eslint-disable-next-line react/prop-types
const Pages = ({ config, state, people }) => {
  const {
    renderOptions: { gridProps },
    pageSize,
    margins,
    cropMarks,
  } = config;

  const badges = people.map(person => {
    const badge = (
      <Builder key={person.id} state={state} person={person} readonly showBorder={config.border} />
    );
    if (cropMarks) {
      const bleedGap = UNIT_BLEED_LENGTH[pageSize.unit];
      const cropMarkLength = UNIT_CROP_MARK_LENGTH[pageSize.unit];
      return (
        <CropMarksContainer key={`${person.id}-crop-marks`}>
          <CropMarks bleedGap={bleedGap} cropMarkLength={cropMarkLength} unit={pageSize.unit} />
          {badge}
        </CropMarksContainer>
      );
    }
    return badge;
  });

  const badgeCount = badges.length;
  const totalPages = Math.ceil(badgeCount / (gridProps.rows * gridProps.cols));

  const pages = Array(totalPages)
    .fill(0)
    .map((_, index) => (
      <Page
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        width={pageSize.width}
        height={pageSize.height}
        margin={margins}
        cropMarks={cropMarks}
      >
        <Grid {...gridProps} fill={config.fill}>
          {getNextBadges({ page: index, gridProps, badges })}
        </Grid>
      </Page>
    ));

  return pages;
};

const useCapturePDF = (query, eventId, resourceType, resourceId) => {
  const token = query.get('token');
  const limit = Number(query.get('limit') ?? 1000);
  const offset = Number(query.get('page') ?? 0) * limit;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [badge, setBadge] = useState(null);
  const [config, setConfig] = useState(null);
  const [people, setPeople] = useState([]);

  useEffect(() => {
    async function effect() {
      setLoading(true);

      try {
        if (resourceType !== BADGES) {
          throw new Error('Unknown resource type');
        }

        const badgeRes = await getBadgeFn(eventId, resourceId, token);
        const parsedConfig = parseConfig(query, badgeRes);
        setConfig(parsedConfig);
        setBadge(badgeRes);

        if (badgeRes.badgeType === BadgeTypes.SIMPLE_LABEL) {
          const personId = query.get('people_id');
          const useMockPeopleData = query.get('use_mock_people_data')?.toLowerCase() === 'true';
          if (useMockPeopleData) {
            setPeople([MOCK_PERON_DATA_FOR_TESTING]);
            return;
          }
          const person = await getPersonFn(eventId, personId, token);
          setPeople([person]);
          return;
        }

        const { data } = await listPeopleFn(eventId, badgeRes, {
          token,
          limit,
          offset,
          sort: parsedConfig.sort,
        });
        setPeople(data);
      } catch (err) {
        const notFound = NOT_FOUND_ERROR_CODES.includes(err?.errors?.[0]?.code);
        setError(notFound ? t`Resource was not found.` : err.message);

        if (!notFound) {
          Sentry.captureException(err);
        }
      } finally {
        setLoading(false);
      }
    }

    effect();
  }, [eventId, query, offset, limit, resourceType, resourceId, token]);

  return {
    loading,
    error,
    badge,
    config,
    people,
  };
};

const CapturePDF = () => {
  const query = useSearchParams();
  const { eventId, resourceType, resourceId } = useParams();
  const { loading, error, badge, config, people } = useCapturePDF(
    query,
    eventId,
    resourceType,
    resourceId
  );

  const [state, dispatch] = useReducer(ENV === 'development' ? logger(reducer) : reducer, {
    present: {
      ...initialState,
    },
  });

  useEffect(() => {
    if (!badge) {
      return;
    }

    dispatch({ type: ACTION_POPULATE, payload: transformFn(badge) });
    dispatch({ type: ACTION_SET_LOADING, payload: false });
  }, [badge]);

  if (error) {
    return <Alert type="error" message={error} showIcon />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <PageStyle
        width={config.pageSize.width}
        height={config.pageSize.height}
        scale={config.scale}
      />
      <Pages config={config} state={state} people={people} />
    </>
  );
};

export default CapturePDF;
