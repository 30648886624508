import { Alert, Button, Form, Input, message, Modal, Select, Typography, Radio } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { jt, t } from 'ttag';

import Label from 'common/components/Label';
import { ISO_LOCALE_MAP, LANGUAGE_BY_ISO } from 'common/services/translation/api';

import {
  invite as inviteFn,
  ADMIN_ID,
  COLLABORATOR_ID,
  update as updateFn,
  ENTITY_ID_ALL,
} from './service';
import { resendInvite as resendInviteFn } from './service-old';

const PENDING_INVITE_ERROR_CODE = 'a88bc9ea-2b19-4cc0-a604-1d6ba1014682';

const ERROR_MESSAGES_BY_CODE = () => ({
  '2c8ac442-81e8-4fc8-ba64-7b1fcadf0cf9': t`This user is already an organizer for this organization.`,
  '4c4d83d6-21b7-47e8-a279-f6bcfc1acd41': t`This language is not supported by Experience Manager`,
  organizer_invitation_error: t`Organizer invite already exists for this email.`,
});

const { Item, useForm, useWatch } = Form;
const { Option } = Select;

const STAGE_FORM = 'form';
const STAGE_PENDING_INVITE = 'pending_invite';

const FAQ_URL = 'https://help.eventmobi.com/en/knowledge/organizer-permission-controls';

const getRoleTip = () => {
  const faqLink = (
    <a key="learn-more" href={FAQ_URL} target="_blank" rel="noopener noreferrer">
      {t`Learn more about organizer roles and permissions`}
    </a>
  );

  return {
    [COLLABORATOR_ID]: jt`Collaborators can access and manage selected events of the organization. Need help? ${faqLink}.`,
    [ADMIN_ID]: jt`Admins have access to all the features on Experience Manager, as well as all the events of the organization. Need help? ${faqLink}.`,
  };
};

const InviteModal = ({ organizationId, organizer, onClose, eventsList }) => {
  const [form] = useForm();

  const [stage, setStage] = useState(STAGE_FORM);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { validateFields, getFieldValue, setFieldValue } = form;

  const roleId = useWatch('roleId', form);
  const eventAccess = useWatch('eventAccess', form);

  const isEditMode = !!organizer;

  const handleSave = async () => {
    setError(null);
    setLoading(true);
    try {
      const values = await validateFields();

      if (isEditMode) {
        await updateFn(organizationId, organizer.id, values);
        message.success(t`Successfully updated organizer`);
      } else {
        await inviteFn(organizationId, values);
        message.success(t`Invitation sent to the organizer`);
      }
      onClose(true, isEditMode);
    } catch (err) {
      setLoading(false);
      if (err?.errorFields) return;
      const code = err?.errors?.[0]?.code;

      if (code === PENDING_INVITE_ERROR_CODE) {
        setStage(STAGE_PENDING_INVITE);
      } else {
        const errorMessage =
          ERROR_MESSAGES_BY_CODE()[code] ?? t`Unknown error occurred while sending invite.`;
        setError(errorMessage);
      }
    }
  };

  const handleResend = async () => {
    const values = await validateFields();

    setError(null);
    setLoading(true);
    try {
      await resendInviteFn(organizationId, values);
      message.success(t`Invitation sent to the organizer`);

      onClose(true);
    } catch (err) {
      const code = err?.errors?.[0]?.code;

      const errorMessage =
        ERROR_MESSAGES_BY_CODE()[code] ?? t`Unknown error occurred while sending invite.`;
      setError(errorMessage);
      setLoading(false);
    }
  };

  const email = <strong key="email">{getFieldValue('email')}</strong>;

  const initialValues = isEditMode
    ? {
        ...organizer,
        eventAccess:
          organizer.eventIds?.[0] === 'all' || organizer.roleId === ADMIN_ID ? 'all' : 'specific',
        eventIds:
          organizer.eventIds?.includes(ENTITY_ID_ALL) || organizer.roleId === ADMIN_ID
            ? []
            : organizer.eventIds,
      }
    : { roleId: ADMIN_ID, eventAccess: 'all', language: 'en' };

  return (
    <Modal
      title={isEditMode ? t`Edit Organizer` : t`Invite Organizer`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={() => onClose(false)}
      footer={
        stage === STAGE_FORM ? (
          <>
            <Button disabled={loading} onClick={() => onClose(false)}>{t`Cancel`}</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              {isEditMode ? t`Save` : t`Send Invite`}
            </Button>
          </>
        ) : (
          <>
            <Button disabled={loading} onClick={() => onClose(false)}>{t`Cancel`}</Button>
            <Button disabled={loading} onClick={() => setStage(STAGE_FORM)}>{t`Edit Email`}</Button>
            <Button
              type="primary"
              loading={loading}
              onClick={handleResend}
            >{t`Send New Invite`}</Button>
          </>
        )
      }
      width={700}
    >
      {error && <Alert showIcon message={error} type="error" style={{ marginBottom: '20px' }} />}

      {stage === STAGE_FORM && (
        <Typography style={{ marginBottom: '20px' }}>
          {isEditMode
            ? t`If there is an update on access permission, an email will be sent to the Organizer.`
            : t`An invitation email with activation instructions will be sent to the new organizer.`}
        </Typography>
      )}

      {stage === STAGE_PENDING_INVITE && (
        <Typography>
          {jt`A pending invite already exists for the email ${email}. Would you like to continue sending a new invite to this recipient?`}
        </Typography>
      )}

      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        hidden={stage !== STAGE_FORM}
        initialValues={initialValues}
      >
        <Item
          label={<Label isRequired>{t`Organizer Email`}</Label>}
          name="email"
          rules={[
            { required: true, message: t`Organizer Email is required` },
            { type: 'email', message: t`Invalid email address` },
          ]}
        >
          <Input disabled={isEditMode} />
        </Item>
        <Item
          label={<Label isRequired>{t`Role`}</Label>}
          name="roleId"
          rules={[{ required: true, message: t`Role is required` }]}
          style={{ marginBottom: '4px' }}
        >
          <Radio.Group>
            <Radio.Button value={ADMIN_ID}>{t`Admin`}</Radio.Button>
            <Radio.Button value={COLLABORATOR_ID}>{t`Collaborator`}</Radio.Button>
          </Radio.Group>
        </Item>

        <Typography>{getRoleTip()[roleId]}</Typography>
        <Item
          name="eventAccess"
          hidden={roleId === ADMIN_ID}
          style={{ margin: '24px 0px 4px 12px' }}
          onChange={e => {
            if (e.target.value === 'specific') {
              setFieldValue('eventIds', []);
            }

            setFieldValue('eventIds');
          }}
        >
          <Radio.Group
            optionType="default"
            style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '150px' }}
          >
            <Radio.Button value="all">{t`All Events`}</Radio.Button>
            <Radio.Button value="specific">{t`Specific Events`}</Radio.Button>
          </Radio.Group>
        </Item>

        <Item
          rules={[
            {
              required: eventAccess === 'specific' && roleId === COLLABORATOR_ID,
              message: t`Selecting at least one event is required`,
            },
          ]}
          name="eventIds"
          hidden={roleId === ADMIN_ID}
          style={{ marginLeft: '12px' }}
        >
          <Select
            placeholder={t`Search`}
            mode="multiple"
            allowClear
            onChange={() => setFieldValue('eventAccess', 'specific')}
            disabled={eventAccess === 'all'}
          >
            {eventsList.map(event => (
              <Option key={event.id} value={event.id}>
                {jt`${event.shortcode} | ${event.name}`}
              </Option>
            ))}
          </Select>
        </Item>
        {!isEditMode && (
          <Item
            label={<Label isRequired>{t`Invite Email Language`}</Label>}
            name="language"
            rules={[{ required: true, message: t`Invite Email Language is required` }]}
            style={{ marginTop: '24px' }}
          >
            <Select>
              {Object.keys(ISO_LOCALE_MAP).map(code => (
                <Option key={code} value={code}>
                  {LANGUAGE_BY_ISO(code)}
                </Option>
              ))}
            </Select>
          </Item>
        )}
      </Form>
    </Modal>
  );
};

InviteModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  organizer: PropTypes.shape({
    email: PropTypes.string.isRequired,
    eventIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
      .isRequired,
    language: PropTypes.string.isRequired,
    roleId: PropTypes.string.isRequired,
  }),
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      shortcode: PropTypes.string.isRequired,
    })
  ),
  onClose: PropTypes.func.isRequired,
};

export default InviteModal;
