import { Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { t, jt } from 'ttag';

import { isEnabled as isFlagEnabled } from 'common/state/flags';

import { useList } from '@domains/Organization/CustomDomain/queries';
import { useShouldShowFeatureQuery } from '@domains/Organization/queries';
import usePermission, { READ_CUSTOM_DOMAINS } from '@hooks/usePermission';

const useCustomDomainAlerts = organizationId => {
  const isDomainStrategyFFEnabled = useSelector(state =>
    isFlagEnabled(state, 'domain_strategy_org_custom_domain')
  );
  const { loading: isLoadingAuth, authorized } = usePermission();

  const {
    data: isCustomDomainEnabled,
    isFetching: isLoadingCustomDomainEnabled,
  } = useShouldShowFeatureQuery(organizationId, 'is_custom_domain_enabled', {
    enabled: isDomainStrategyFFEnabled && authorized(READ_CUSTOM_DOMAINS),
  });

  const { data, isFetching } = useList(
    organizationId,
    {
      limit: 1,
      verificationStatus: 'failed,expired',
      hasLinkedEvents: true,
    },
    !!organizationId && !!isCustomDomainEnabled && !isLoadingAuth && authorized(READ_CUSTOM_DOMAINS)
  ); // !! because it will be undefined if the feature flag is not enabled

  const manageCustomDomainsLink = organizationId && (
    <Link
      key="manageCustomDomainsLink"
      to={generatePath('/organization/:organizationId/custom-domain', { organizationId })}
    >{t`Manage custom domain`}</Link>
  );
  const eventUrl = <Typography.Text strong>eventmobi.com/eventcode</Typography.Text>;

  if (isFetching || isLoadingCustomDomainEnabled) {
    return { loading: true, alerts: [] };
  }

  const disconnectedDomainsCount = data?.meta.pagination.totalItemsCount;

  if (disconnectedDomainsCount > 0) {
    return {
      isLoading: false,
      alerts: [
        {
          id: 'customDomain',
          content: jt`${disconnectedDomainsCount} Custom domain(s) have failed or expired. Attendees will not be able to access your event space using these domain(s). Event Space will be accessible through default URL - ${eventUrl} only. ${manageCustomDomainsLink}`,
          type: 'error',
          scope: 'organization',
        },
      ],
    };
  }

  return { loading: false, alerts: [] };
};

export default useCustomDomainAlerts;
