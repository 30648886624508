import { RightOutlined, LinkOutlined } from '@ant-design/icons';
import { Divider, Typography, List as AntList, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { t, jt } from 'ttag';

import Loading from 'common/components/Loading';
import { unsetOrganization } from 'common/state/organization';
import { getFirstName, getLastName, getEmail, getIsStaff } from 'common/state/user';

import { list as listFn } from './service';

const { Title: AntTitle, Text } = Typography;
const { Meta } = AntList.Item;

const MANAGEMOBI_ROUTE = '/admin/masterlist/search';

/*
Note: EXP is available in more languages than just English and German,
but only those two are defined here because the support site is currently
only in EN and DE.
Portuguese also exists in Support but it's completely empty.
Missing languages fallback to EN.
*/
const KNOWLEDGE_BASE_URL = 'https://help.eventmobi.com/en/knowledge';

const WHATS_NEW_URL = 'https://help.eventmobi.com/en/knowledge/new-features-updates';

const Title = styled(AntTitle)`
  margin-top: ${props => props.$heading && '52px'};
  && {
    color: #595959;
    font-weight: 400;
  }
`;

const Item = styled(AntList.Item)`
  padding-left: 16px;
  padding-right: 16px;
  height: 54px;
  width: 450px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  margin-top: ${props => props.$footer && '32px'};

  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.85);
    text-decoration: none;
  }

  h4 {
    margin-bottom: 0;
  }

  &&& {
    border-bottom: ${props => (props.$footer || props.$lastItem ? '1px solid #e8e8e8' : '0')};
  }
`;

const Footer = styled(AntList.Item)`
  margin-top: 16px;
  font-size: 12px;
  .ant-list-item-meta-title {
    font-size: 12px;
    color: #595959;
  }
`;

const selector = createSelector(
  getFirstName,
  getLastName,
  getEmail,
  getIsStaff,
  (firstName, lastName, userEmail, isStaff) => ({
    displayName: firstName || lastName ? `${firstName} ${lastName}` : userEmail,
    isStaff,
  })
);

function List() {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { isStaff, displayName } = useSelector(selector);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function effect() {
      try {
        const result = await listFn();
        setList(result);
      } finally {
        dispatch(unsetOrganization());
        setLoading(false);
      }
    }

    effect();
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  const EMLink = (
    <a key="em" href="https://eventmobi.com" rel="nofollow noopener noreferrer" target="_blank">
      EventMobi
    </a>
  );

  return (
    <>
      <Row type="flex" justify="center">
        <Title $heading level={2}>{t`Hello ${displayName}`}</Title>
      </Row>
      <Row type="flex" justify="center">
        <Title level={5}>{t`Welcome back to the EventMobi Experience Manager`}</Title>
      </Row>
      <Divider />
      <Row type="flex" justify="center">
        <Title level={5}>{t`To get started, please select an organization:`}</Title>
      </Row>
      <Row type="flex" justify="center">
        <AntList
          dataSource={list}
          renderItem={item => (
            <Item>
              <Link to={`${url}/${item?.id}`}>
                <Meta title={item?.name} />
                <RightOutlined />
              </Link>
            </Item>
          )}
          footer={
            <>
              {isStaff && (
                <Item $footer>
                  <Link to={MANAGEMOBI_ROUTE}>
                    <Meta title={<Text style={{ color: '#f5222d' }}>{t`ManageMobi`}</Text>} />
                    <RightOutlined />
                  </Link>
                </Item>
              )}
              <Divider />
              <Item>
                <a href={WHATS_NEW_URL} target="_blank" rel="nofollow noopener noreferrer">
                  <Meta title={t`New Features & Updates`} />
                  <LinkOutlined />
                </a>
              </Item>
              <Item $lastItem>
                <a href={KNOWLEDGE_BASE_URL} target="_blank" rel="nofollow noopener noreferrer">
                  <Meta title={t`EventMobi Knowledge Base`} />
                  <LinkOutlined />
                </a>
              </Item>
              <Footer>
                <Meta
                  title={
                    <>
                      <a
                        href="https://eventmobi.com/terms-of-use/"
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                      >
                        {t`Terms of Use`}
                      </a>
                      <Divider type="vertical" />
                      <a
                        href="https://eventmobi.com/privacy-policy/"
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                      >
                        {t`Privacy Policy`}
                      </a>
                    </>
                  }
                />
                <div>{jt`Powered by ${EMLink}™`}</div>
              </Footer>
            </>
          }
        />
      </Row>
    </>
  );
}

export default List;
