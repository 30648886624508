import { Tabs as AntTabs, Typography } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { t } from 'ttag';

import HTTP404 from 'common/components/ErrorRoutes/HTTP404';
import Loading from 'common/components/Loading';
import { isEnabled as isFlagEnabled } from 'common/state/flags';
import { getId, getLicenseType, getName } from 'common/state/organization';

import usePermission, {
  UPDATE_ORGANIZATION,
  READ_API_KEYS,
  READ_APPS,
  READ_DATA_USAGE,
  READ_EVENTS,
  READ_ORGANIZERS,
  READ_SSO_CONNECTIONS,
  READ_SENDER_EMAIL_DOMAINS,
  READ_PAYMENT_GATEWAY,
  READ_CUSTOM_DOMAINS,
} from '@hooks/usePermission';

import Admin from './Admin';
import Apps from './App';
import CustomDomain from './CustomDomain';
import PeopleCredits from './DataUsage/PeopleCredit';
import { useContracts } from './DataUsage/PeopleCredit/queries';
import VideoUsage from './DataUsage/Video';
import EmailDomain from './EmailDomain';
import Events from './Event';
import Integrations from './Integration';
import Organizers from './Organizer';
import PaymentGateways from './PaymentGateway';
import { useShouldShowFeatureQuery } from './queries';
import { LICENSE_TYPE_USER_CREDIT } from './service';
import SSO from './SSO';

const { Title: AntTitle, Text } = Typography;

export const TAB_ADMIN = 'admin';
export const TAB_APPS = 'apps';
export const TAB_EVENTS = 'events';
export const TAB_INTEGRATIONS = 'integrations';
export const TAB_ORGANIZERS = 'organizers';
export const TAB_SSO = 'sso';
export const TAB_EMAIL_DOMAIN = 'email-domain';
export const TAB_PAYMENT_GATEWAYS = 'payment-gateways';
export const TAB_DATA_USAGE = 'data-usage';
export const TAB_PEOPLE_CREDIT = 'people-credit';
export const TAB_VIDEO_USAGE = 'video-usage';
export const TAB_CUSTOM_DOMAIN = 'custom-domain';

const validTabs = [
  TAB_ADMIN,
  TAB_APPS,
  TAB_EVENTS,
  TAB_DATA_USAGE,
  TAB_ORGANIZERS,
  TAB_INTEGRATIONS,
  TAB_SSO,
  TAB_EMAIL_DOMAIN,
  TAB_PAYMENT_GATEWAYS,
  TAB_PEOPLE_CREDIT,
  TAB_VIDEO_USAGE,
  TAB_CUSTOM_DOMAIN,
];

const Title = styled(AntTitle)`
  margin-left: 45px;
  margin-top: 40px;
  padding-bottom: 40px;
  && {
    font-weight: 400;
  }
`;

const Tabs = styled(AntTabs)`
  .ant-tabs-tabpane {
    padding: 10px 45px;
  }
`;

const selector = createSelector(getId, getName, getLicenseType, (id, name, licenseType) => ({
  id,
  name,
  licenseType,
}));

const Details = () => {
  const history = useHistory();
  const { params, path } = useRouteMatch();
  const { organizationId, tab } = params;
  const { name, licenseType } = useSelector(selector);
  const isEmailDomainsFFEnabled = useSelector(state =>
    isFlagEnabled(state, 'exp_email_spam_improvements')
  );
  const isDomainStrategyFFEnabled = useSelector(state =>
    isFlagEnabled(state, 'domain_strategy_org_custom_domain')
  );
  const { loading: isLoadingAuth, authorized } = usePermission();
  const {
    data: showEmailDomainTab,
    isLoading: isShowEmailDomainsLoading,
  } = useShouldShowFeatureQuery(organizationId, 'is_whitelabel_sender_email_domain_enabled', {
    enabled: isEmailDomainsFFEnabled && authorized(READ_SENDER_EMAIL_DOMAINS),
  });

  const { data: contracts, isLoading: contractsLoading } = useContracts(organizationId);
  // show people credits even if license type is event credit but they have contracts, so that they can switch to event credit
  const renderPeopleCredits =
    !contractsLoading && (contracts.length > 0 || licenseType === LICENSE_TYPE_USER_CREDIT);

  const [activeTab, setActiveTab] = useState(tab);

  const { data: showSSOTab, isLoading: isShowSSOLoading } = useShouldShowFeatureQuery(
    organizationId,
    'is_sso_enabled',
    {
      enabled: authorized(READ_SSO_CONNECTIONS),
    }
  );
  const {
    data: showPaymentGatewaysTab,
    isLoading: isShowPaymentGatewaysTabLoading,
  } = useShouldShowFeatureQuery(organizationId, 'reg_payments');

  if (!validTabs.includes(tab)) {
    return (
      <HTTP404
        scoped
        ctaText={t`Return to Dashboard`}
        ctaHref={generatePath('organization/:organizationId', { organizationId })}
      />
    );
  }

  const isLoading =
    (isShowSSOLoading && authorized(READ_SSO_CONNECTIONS)) ||
    (isEmailDomainsFFEnabled &&
      isShowEmailDomainsLoading &&
      authorized(READ_SENDER_EMAIL_DOMAINS)) ||
    isShowPaymentGatewaysTabLoading ||
    isLoadingAuth;

  if (isLoading) {
    return <Loading />;
  }

  const tabs = [
    authorized(READ_EVENTS) && {
      label: t`Events`,
      key: TAB_EVENTS,
      children: <Events active={activeTab === TAB_EVENTS} />,
    },
    authorized(READ_ORGANIZERS) && {
      label: t`Organizers`,
      key: TAB_ORGANIZERS,
      children: <Organizers />,
    },
    authorized(READ_DATA_USAGE) &&
      renderPeopleCredits && {
        label: t`People Credit`,
        key: TAB_PEOPLE_CREDIT,
        style: { padding: '0' },
        children: <PeopleCredits contracts={contracts} />,
      },
    authorized(READ_DATA_USAGE) && {
      label: t`Video Usage`,
      key: TAB_VIDEO_USAGE,
      style: { padding: '0' },
      children: <VideoUsage />,
    },
    authorized(READ_API_KEYS) && {
      label: t`Integrations`,
      key: TAB_INTEGRATIONS,
      children: <Integrations />,
    },
    authorized(READ_SSO_CONNECTIONS) &&
      showSSOTab && {
        label: t`SSO Connections`,
        key: TAB_SSO,
        children: <SSO active={activeTab === TAB_SSO} />,
      },
    authorized(READ_SENDER_EMAIL_DOMAINS) &&
      showEmailDomainTab &&
      isEmailDomainsFFEnabled && {
        label: t`Email Domains`,
        key: TAB_EMAIL_DOMAIN,
        children: <EmailDomain active={activeTab === TAB_EMAIL_DOMAIN} />,
      },
    authorized(READ_PAYMENT_GATEWAY) &&
      showPaymentGatewaysTab && {
        label: t`Payment Gateways`,
        key: TAB_PAYMENT_GATEWAYS,
        children: <PaymentGateways active={activeTab === TAB_PAYMENT_GATEWAYS} />,
      },
    authorized(READ_CUSTOM_DOMAINS) &&
      isDomainStrategyFFEnabled && {
        label: t`Custom Domain`,
        key: TAB_CUSTOM_DOMAIN,
        children: <CustomDomain />,
      },
    authorized(READ_APPS) && {
      label: <Text style={{ color: '#f5222d' }}>{t`Apps`}</Text>,
      key: TAB_APPS,
      children: <Apps />,
    },
    authorized(UPDATE_ORGANIZATION) && {
      label: <Text style={{ color: '#f5222d' }}>{t`Admin`}</Text>,
      key: TAB_ADMIN,
      style: { padding: '0' },
      children: <Admin />,
    },
  ].filter(Boolean);

  if (!tabs.some(({ key }) => key === tab)) {
    window.location.replace('/forbidden');
    return null;
  }

  return (
    <>
      <Title style={{ color: '#595959' }}>{name}</Title>
      <Tabs
        type="card"
        activeKey={tab}
        style={{ overflow: 'visible' }}
        tabBarStyle={{ marginBottom: 0, paddingLeft: 45 }}
        onChange={newTab => {
          setActiveTab(newTab);
          history.push(generatePath(path, { organizationId: params.organizationId, tab: newTab }));
        }}
        items={tabs}
        destroyInactiveTabPane
      />
    </>
  );
};

export default Details;
