import {
  ApiOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  GlobalOutlined,
  LayoutOutlined,
  MailOutlined,
  MobileOutlined,
  PieChartOutlined,
  TagOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Menu as AntMenu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, matchPath, Link, useLocation, useParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { t } from 'ttag';

import useConfig from 'common/hooks/useConfig';
import { SIDE_NAV_WIDTH, useSideNavExpanded } from 'common/hooks/useSideNavExpanded';
import { isEnabled } from 'common/state/event';
import { isEnabled as isFlagEnabled } from 'common/state/flags';
import { getIsStaff } from 'common/state/user';

const ITEM_EVENT_DETAILS = 'ITEM_EVENT_DETAILS';
const ITEM_PRIVACY_AND_TERMS = 'ITEM_PRIVACY_AND_TERMS';
const ITEM_PEOPLE = 'ITEM_PEOPLE';
const ITEM_SESSIONS = 'ITEM_SESSIONS';
const ITEM_COMPANIES = 'ITEM_COMPANIES';
const ITEM_DOCUMENTS = 'ITEM_DOCUMENTS';
const ITEM_VIDEOS = 'ITEM_VIDEOS';
const ITEM_MAPS = 'ITEM_MAPS';
const ITEM_SURVEYS = 'ITEM_SURVEYS';
const ITEM_WIDGET_PAGES = 'ITEM_WIDGET_PAGES';
const ITEM_EVENT_APP = 'ITEM_EVENT_APP';
const ITEM_EVENT_APP_HOME_PAGE = 'ITEM_EVENT_APP_HOME_PAGE';
const ITEM_EVENT_APP_SECTIONS = 'ITEM_EVENT_APP_SECTIONS';
const ITEM_EVENT_APP_GROUP_DISCUSSIONS = 'ITEM_EVENT_APP_GROUP_DISCUSSIONS';
const ITEM_EVENT_APP_DESIGN_STUDIO = 'ITEM_EVENT_APP_DESIGN_STUDIO';
const ITEM_EVENT_APP_ACTIVITY_FEED = 'ITEM_EVENT_APP_ACTIVITY_FEED';
const ITEM_EVENT_APP_BANNERS = 'ITEM_EVENT_APP_BANNERS';
const ITEM_EVENT_APP_SETTINGS = 'ITEM_EVENT_APP_SETTINGS';
const ITEM_BADGE_DESIGNER = 'ITEM_BADGE_DESIGNER';
const ITEM_GAMIFICATION = 'ITEM_GAMIFICATION';
const ITEM_AUDIENCE_RESPONSE = 'ITEM_AUDIENCE_RESPONSE';
const ITEM_LIVE_DISPLAY = 'ITEM_LIVE_DISPLAY';
const ITEM_ANNOUNCEMENTS = 'ITEM_ANNOUNCEMENTS';
// TODO: EXP-16988 - Remove announcements old route
const ITEM_ANNOUNCEMENTS_OLD = 'ITEM_ANNOUNCEMENTS_OLD';
const ITEM_ONSITE = 'ITEM_ONSITE';
const ITEM_ONSITE_CHECK_IN = 'ITEM_ONSITE_CHECK_IN';
const ITEM_ONSITE_LEAD_CAPTURE = 'ITEM_ONSITE_LEAD_CAPTURE';
const ITEM_INTEGRATIONS = 'ITEM_INTEGRATIONS';
const ITEM_ANALYTICS = 'ITEM_ANALYTICS';
const ITEM_AUDIT_LOG = 'ITEM_AUDIT_LOG';
const ITEM_EVENT_CONFIGURATION = 'ITEM_EVENT_CONFIGURATION';
const ITEM_COMMUNICATIONS = 'ITEM_EVENT_COMMUNICATIONS';
const ITEM_COMMUNICATIONS_EMAILS = 'ITEM_COMMUNICATIONS_EMAILS';
const ITEM_REGISTRATION = 'ITEM_REGISTRATION';
const ITEM_REGISTRATION_DETAILS = 'ITEM_REGISTRATION_DETAILS';
const ITEM_REGISTRATION_DESIGN_STUDIO = 'ITEM_REGISTRATION_DESIGN_STUDIO';
const ITEM_REGISTRATION_EMAILS = 'ITEM_REGISTRATION_EMAILS';
const ITEM_REGISTRATION_ORDERS = 'ITEM_REGISTRATION_ORDERS';
const ITEM_REGISTRATION_REG_FORM = 'ITEM_REGISTRATION_REG_FORM';
const ITEM_REGISTRATION_PROMO_CODES = 'ITEM_REGISTRATION_PROMO_CODES';
const ITEM_REGISTRATION_TICKETS = 'ITEM_REGISTRATION_TICKETS';
const ITEM_WEBSITE_BUILDER = 'ITEM_WEBSITE_BUILDER';

const StyleOverrides = createGlobalStyle`
  .ant-menu-item-group-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .ant-menu-item-divider {
    && {
      border-color: rgba(255, 255, 255, 0.25);
    }
  }
  `;

const Menu = styled(AntMenu)`
  height: 100%;
  overflow: auto;
  width: ${SIDE_NAV_WIDTH.Expanded};
  flex-shrink: 0;
  z-index: 999;

  & .ant-menu-inline.ant-menu-sub {
    background: #192738;
  }

  &.ant-menu-inline-collapsed {
    width: ${SIDE_NAV_WIDTH.Collapsed};
  }
`;

const organizationPath = `/organization/:organizationId`;
const basepath = `${organizationPath}/event/:eventId`;

const routeNavMap = {
  [ITEM_EVENT_DETAILS]: {
    path: [basepath, `${basepath}/pages/details`],
    exact: true,
  },
  [ITEM_PRIVACY_AND_TERMS]: {
    path: `${basepath}/privacy`,
    exact: true,
  },
  [ITEM_PEOPLE]: {
    path: [`${basepath}/people`, `${basepath}/pages/people`],
    exact: false,
  },
  [ITEM_SESSIONS]: {
    path: [`${basepath}/sessions`, `${basepath}/pages/sessions`],
    exact: false,
  },
  [ITEM_COMPANIES]: {
    path: [`${basepath}/companies`, `${basepath}/pages/companies`],
    exact: false,
  },
  [ITEM_DOCUMENTS]: {
    path: [`${basepath}/documents`, `${basepath}/pages/documents`],
    exact: true,
  },
  [ITEM_VIDEOS]: {
    path: [`${basepath}/videos`, `${basepath}/pages/videos`],
    exact: false,
  },
  [ITEM_MAPS]: {
    path: [`${basepath}/maps`, `${basepath}/image-map`],
    exact: false,
  },
  [ITEM_SURVEYS]: {
    path: [`${basepath}/surveys`, `${basepath}/pages/surveys`],
    exact: false,
  },
  [ITEM_WIDGET_PAGES]: {
    path: `${basepath}/designer-pages`,
    exact: false,
  },
  [ITEM_EVENT_APP_HOME_PAGE]: {
    path: `${basepath}/event-app/home-page`,
    exact: true,
  },
  [ITEM_EVENT_APP_SECTIONS]: {
    path: `${basepath}/event-app/sections`,
    exact: true,
  },
  [ITEM_EVENT_APP_GROUP_DISCUSSIONS]: {
    path: `${basepath}/event-app/discussions`,
    exact: true,
  },
  [ITEM_EVENT_APP_DESIGN_STUDIO]: {
    path: `${basepath}/event-app/studio`,
    exact: true,
  },
  [ITEM_EVENT_APP_ACTIVITY_FEED]: {
    path: `${basepath}/event-app/activity-feed`,
    exact: true,
  },
  [ITEM_EVENT_APP_BANNERS]: {
    path: `${basepath}/event-app/banner-ads`,
    exact: true,
  },
  [ITEM_EVENT_APP_SETTINGS]: {
    path: `${basepath}/event-app/settings`,
    exact: true,
  },
  [ITEM_BADGE_DESIGNER]: {
    path: `${basepath}/badge-designer`,
    exact: false,
  },
  [ITEM_GAMIFICATION]: {
    path: `${basepath}/gamification`,
    exact: true,
  },
  [ITEM_AUDIENCE_RESPONSE]: {
    path: `${basepath}/ars/settings`,
    exact: true,
  },
  [ITEM_LIVE_DISPLAY]: {
    path: [`${basepath}/live-display`, `${basepath}/pages/live-display`],
    exact: false,
  },
  [ITEM_ANNOUNCEMENTS_OLD]: {
    path: `${basepath}/announcements`,
    exact: true,
  },
  [ITEM_ONSITE_CHECK_IN]: {
    path: `${basepath}/onsite/check-in`,
    exact: true,
  },
  [ITEM_ONSITE]: {
    path: `${basepath}/onsite`,
    exact: true,
  },
  [ITEM_ONSITE_LEAD_CAPTURE]: {
    path: `${basepath}/onsite/lead-capture`,
    exact: true,
  },
  [ITEM_COMMUNICATIONS_EMAILS]: {
    path: `${basepath}/communications/emails`,
    exact: false,
  },
  [ITEM_INTEGRATIONS]: {
    path: [`${basepath}/integrations`, `${basepath}/pages/integrations`],
    exact: true,
  },
  [ITEM_ANNOUNCEMENTS]: {
    path: `${basepath}/communications/announcements`,
    exact: true,
  },
  [ITEM_ANALYTICS]: {
    path: `${basepath}/analytics`,
    exact: true,
  },
  [ITEM_AUDIT_LOG]: {
    path: `${basepath}/audit-log`,
    exact: true,
  },
  [ITEM_EVENT_CONFIGURATION]: {
    path: `${basepath}/configuration`,
    exact: true,
  },
  [ITEM_REGISTRATION_DETAILS]: {
    path: `${basepath}/registration/details`,
    exact: true,
  },
  // TODO: EXP-17450 - Remove once the website builder has been launched.
  [ITEM_REGISTRATION_DESIGN_STUDIO]: {
    path: `${basepath}/registration/studio`,
    exact: true,
  },
  [ITEM_REGISTRATION_EMAILS]: {
    path: `${basepath}/registration/email`,
    exact: true,
  },
  [ITEM_REGISTRATION_ORDERS]: {
    path: `${basepath}/registration/orders`,
    exact: false,
  },
  [ITEM_REGISTRATION_PROMO_CODES]: {
    path: `${basepath}/registration/promo-codes`,
    exact: true,
  },
  [ITEM_REGISTRATION_REG_FORM]: {
    path: `${basepath}/registration/form`,
    exact: true,
  },
  [ITEM_REGISTRATION_TICKETS]: {
    path: `${basepath}/registration/tickets`,
    exact: true,
  },
  [ITEM_WEBSITE_BUILDER]: {
    path: `${basepath}/website-builder`,
    exact: false,
  },
};

// List of paths where we don't want to show the navigation sidebar.
const noNavigationPaths = [
  `${routeNavMap.ITEM_COMMUNICATIONS_EMAILS.path}/templates/:emailDesignId/editor`,
  `${routeNavMap.ITEM_WEBSITE_BUILDER.path}/templates/:pageDesignId/editor`,
];

const isNoNavigationPath = path =>
  noNavigationPaths.some(noNavPath => matchPath(path, { path: noNavPath, exact: true }));

const getSelectedMenuKeys = url => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, route] of Object.entries(routeNavMap)) {
    if (matchPath(url, route)) {
      return [key];
    }
  }

  return [ITEM_EVENT_DETAILS];
};

const Navigation = () => {
  const isSideNavExpanded = useSideNavExpanded();
  const { organizationId, eventId } = useParams();
  const { pathname } = useLocation();
  const config = useConfig();
  const isRegistrationEnabled = useSelector(state =>
    isEnabled(state, 'is_registration_v2_enabled')
  );
  const isGamificationEnabled = useSelector(state => isEnabled(state, 'is_gamify_app_enabled'));
  const isCompaniesEnabled = useSelector(state => isEnabled(state, 'is_companies_enabled'));
  const isDocumentUploadEnabled = config.isEnabled('is_document_upload_enabled');
  const isSurveyEnabled = useSelector(state => isEnabled(state, 'is_survey_enabled'));
  const isGroupDiscussionsEnabled = useSelector(state =>
    isEnabled(state, 'is_group_discussions_enabled')
  );
  const isActivityFeedEnabled = useSelector(state => isEnabled(state, 'is_activity_feed_enabled'));
  const isSponsorshipEnabled = useSelector(state => isEnabled(state, 'is_sponsorship_enabled'));
  const isCommunicationsEnabled = useSelector(state =>
    isEnabled(state, 'is_communications_enabled')
  );

  const isIntegrationDashboardEnabled = useSelector(state =>
    isFlagEnabled(state, 'integrations_dashboard')
  );
  const isRegFieldsEnabled = useSelector(state => isFlagEnabled(state, 'reg_field_updates'));
  const isVideoLibraryEnabled = useSelector(state => isEnabled(state, 'is_video_library_enabled'));
  const isNativeStreamEnabled = useSelector(state => isEnabled(state, 'native_livestream'));
  const isRtmpStreamingEnabled = useSelector(state => isEnabled(state, 'rtmp_streaming'));
  const isWebsiteBuilderEnabled = useSelector(state =>
    isEnabled(state, 'is_website_builder_enabled')
  );
  const isOnsiteBadgePrintingEnabled = useSelector(state =>
    isFlagEnabled(state, 'onsite_badge_printing')
  );

  const isStaff = useSelector(getIsStaff);

  const getNavPath = key => {
    const firstPath = Array.isArray(routeNavMap[key].path)
      ? routeNavMap[key].path[0]
      : routeNavMap[key].path;
    return generatePath(firstPath, { organizationId, eventId });
  };

  if (isNoNavigationPath(pathname)) {
    return null;
  }

  // TODO: Submenu background color should be lighter than the parent?
  /** @type {Array<import('antd/lib/menu/hooks/useItems').MenuItemType>} */
  const items = [
    {
      label: t`General`,
      icon: <GlobalOutlined />,
      children: [
        {
          key: ITEM_EVENT_DETAILS,
          label: <Link to={getNavPath(ITEM_EVENT_DETAILS)}>{t`Event Details`}</Link>,
        },
        {
          key: ITEM_PRIVACY_AND_TERMS,
          label: <Link to={getNavPath(ITEM_PRIVACY_AND_TERMS)}>{t`Privacy & Terms`}</Link>,
        },
      ],
    },
    {
      label: t`Libraries`,
      icon: <DatabaseOutlined />,
      children: [
        {
          key: ITEM_PEOPLE,
          label: <Link to={getNavPath(ITEM_PEOPLE)}>{t`People`}</Link>,
        },
        {
          key: ITEM_SESSIONS,
          label: <Link to={getNavPath(ITEM_SESSIONS)}>{t`Sessions`}</Link>,
        },
        isCompaniesEnabled && {
          key: ITEM_COMPANIES,
          label: <Link to={getNavPath(ITEM_COMPANIES)}>{t`Companies`}</Link>,
        },
        isDocumentUploadEnabled && {
          key: ITEM_DOCUMENTS,
          label: <Link to={getNavPath(ITEM_DOCUMENTS)}>{t`Documents`}</Link>,
        },
        (isVideoLibraryEnabled || isNativeStreamEnabled || isRtmpStreamingEnabled) && {
          key: ITEM_VIDEOS,
          label: <Link to={getNavPath(ITEM_VIDEOS)}>{t`Videos`}</Link>,
        },
        {
          key: ITEM_MAPS,
          label: <Link to={getNavPath(ITEM_MAPS)}>{t`Maps`}</Link>,
        },
        isSponsorshipEnabled && {
          key: ITEM_EVENT_APP_BANNERS,
          label: <Link to={getNavPath(ITEM_EVENT_APP_BANNERS)}>{t`Banners`}</Link>,
        },
      ],
    },
    isWebsiteBuilderEnabled && {
      key: ITEM_WEBSITE_BUILDER,
      icon: <DesktopOutlined />,
      label: <Link to={getNavPath(ITEM_WEBSITE_BUILDER)}>{t`Website`}</Link>,
    },
    {
      key: ITEM_COMMUNICATIONS,
      icon: <MailOutlined />,
      label: t`Communications`,
      children: [
        isCommunicationsEnabled && {
          key: ITEM_COMMUNICATIONS_EMAILS,
          label: <Link to={getNavPath(ITEM_COMMUNICATIONS_EMAILS)}>{t`Email`}</Link>,
        },
        {
          key: ITEM_ANNOUNCEMENTS,
          label: <Link to={getNavPath(ITEM_ANNOUNCEMENTS)}>{t`Announcements`}</Link>,
        },
      ],
    },
    isRegistrationEnabled && {
      key: ITEM_REGISTRATION,
      icon: <TagOutlined />,
      label: t`Registration`,
      children: [
        {
          key: ITEM_REGISTRATION_DETAILS,
          label: <Link to={getNavPath(ITEM_REGISTRATION_DETAILS)}>{t`Registration Details`}</Link>,
        },
        {
          key: ITEM_REGISTRATION_TICKETS,
          label: <Link to={getNavPath(ITEM_REGISTRATION_TICKETS)}>{t`Tickets`}</Link>,
        },
        isRegFieldsEnabled && {
          key: ITEM_REGISTRATION_REG_FORM,
          label: <Link to={getNavPath(ITEM_REGISTRATION_REG_FORM)}>{t`Registration Form`}</Link>,
        },
        {
          key: ITEM_REGISTRATION_PROMO_CODES,
          label: <Link to={getNavPath(ITEM_REGISTRATION_PROMO_CODES)}>{t`Promo Codes`}</Link>,
        },
        // TODO: EXP-17450 - Remove once the website builder has been launched.
        !isWebsiteBuilderEnabled && {
          key: ITEM_REGISTRATION_DESIGN_STUDIO,
          label: <Link to={getNavPath(ITEM_REGISTRATION_DESIGN_STUDIO)}>{t`Design`}</Link>,
        },
        {
          key: ITEM_REGISTRATION_EMAILS,
          label: <Link to={getNavPath(ITEM_REGISTRATION_EMAILS)}>{t`Emails`}</Link>,
        },
        {
          key: ITEM_REGISTRATION_ORDERS,
          label: <Link to={getNavPath(ITEM_REGISTRATION_ORDERS)}>{t`Orders`}</Link>,
        },
      ],
    },
    {
      key: ITEM_EVENT_APP,
      icon: <LayoutOutlined />,
      label: t`Event App`,
      children: [
        {
          key: ITEM_EVENT_APP_SECTIONS,
          label: <Link to={getNavPath(ITEM_EVENT_APP_SECTIONS)}>{t`Menu Sections`}</Link>,
        },
        {
          key: ITEM_EVENT_APP_HOME_PAGE,
          label: <Link to={getNavPath(ITEM_EVENT_APP_HOME_PAGE)}>{t`Home Page`}</Link>,
        },
        isActivityFeedEnabled && {
          key: ITEM_EVENT_APP_ACTIVITY_FEED,
          label: <Link to={getNavPath(ITEM_EVENT_APP_ACTIVITY_FEED)}>{t`Activity Feed`}</Link>,
        },
        isGroupDiscussionsEnabled && {
          key: ITEM_EVENT_APP_GROUP_DISCUSSIONS,
          label: (
            <Link to={getNavPath(ITEM_EVENT_APP_GROUP_DISCUSSIONS)}>{t`Group Discussions`}</Link>
          ),
        },
        isGamificationEnabled && {
          key: ITEM_GAMIFICATION,
          label: <Link to={getNavPath(ITEM_GAMIFICATION)}>{t`Gamification`}</Link>,
        },
        isSurveyEnabled && {
          key: ITEM_SURVEYS,
          label: <Link to={getNavPath(ITEM_SURVEYS)}>{t`Surveys`}</Link>,
        },
        {
          key: ITEM_AUDIENCE_RESPONSE,
          label: <Link to={getNavPath(ITEM_AUDIENCE_RESPONSE)}>{t`Audience Response`}</Link>,
        },
        {
          key: ITEM_EVENT_APP_DESIGN_STUDIO,
          label: (
            <Link to={getNavPath(ITEM_EVENT_APP_DESIGN_STUDIO)}>
              {isRegFieldsEnabled ? t`Event App Design` : t`Event Design Studio`}
            </Link>
          ),
        },
        {
          key: ITEM_EVENT_APP_SETTINGS,
          label: <Link to={getNavPath(ITEM_EVENT_APP_SETTINGS)}>{t`Event App Settings`}</Link>,
        },
        {
          key: ITEM_WIDGET_PAGES,
          label: <Link to={getNavPath(ITEM_WIDGET_PAGES)}>{t`Page Designer`}</Link>,
        },
      ],
    },
    {
      label: t`Onsite Tools`,
      icon: <MobileOutlined />,
      children: [
        {
          key: ITEM_BADGE_DESIGNER,
          label: <Link to={getNavPath(ITEM_BADGE_DESIGNER)}>{t`Badge Designer`}</Link>,
        },
        {
          key: ITEM_ONSITE,
          // Remove condition and else part when removing onsite_badge_printing FF
          label: isOnsiteBadgePrintingEnabled ? (
            <Link to={getNavPath(ITEM_ONSITE)}>{t`Onsite App`}</Link>
          ) : (
            t`Onsite App`
          ),
          ...(!isOnsiteBadgePrintingEnabled && {
            children: [
              {
                key: ITEM_ONSITE_CHECK_IN,
                label: <Link to={getNavPath(ITEM_ONSITE_CHECK_IN)}>{t`Check In`}</Link>,
              },
            ],
          }),
        },
        {
          key: ITEM_ONSITE_LEAD_CAPTURE,
          label: <Link to={getNavPath(ITEM_ONSITE_LEAD_CAPTURE)}>{t`Lead Capture App`}</Link>,
        },
        {
          key: ITEM_LIVE_DISPLAY,
          label: <Link to={getNavPath(ITEM_LIVE_DISPLAY)}>{t`Live Display`}</Link>,
        },
      ],
    },
    isIntegrationDashboardEnabled && {
      key: ITEM_INTEGRATIONS,
      icon: <ApiOutlined />,
      label: <Link to={getNavPath(ITEM_INTEGRATIONS)}>{t`Integrations`}</Link>,
    },
    {
      key: ITEM_ANALYTICS,
      icon: <PieChartOutlined />,
      label: <Link to={getNavPath(ITEM_ANALYTICS)}>{t`Analytics`}</Link>,
    },
    {
      label: t`Admin`,
      icon: <ToolOutlined />,
      children: [
        {
          key: ITEM_AUDIT_LOG,
          label: <Link to={getNavPath(ITEM_AUDIT_LOG)}>{t`Log Inspector`}</Link>,
        },
        isStaff && {
          key: ITEM_EVENT_CONFIGURATION,
          label: <Link to={getNavPath(ITEM_EVENT_CONFIGURATION)}>{t`Event Configuration`}</Link>,
        },
      ],
    },
  ];

  return [
    <StyleOverrides key="overrides" />,
    <Menu
      key="left-nav"
      selectedKeys={getSelectedMenuKeys(pathname)}
      mode="inline"
      theme="dark"
      items={items}
      inlineCollapsed={!isSideNavExpanded}
    />,
  ];
};

export default Navigation;
