import { Tabs, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from 'ttag';

import { isEnabled as isFlagEnabled } from 'common/state/flags';

import useTab from '@hooks/useTab';

import CompanyInfo from './CompanyInfo';
import { COMPANY_INFO_TAB, EVENT_INFO_TAB, SETTINGS_TAB } from './constants';
import EventInfo from './EventInfo';
import EventInfoOld from './EventInfoOld';
import Settings from './Settings';

const { Title: AntTitle } = Typography;

const Title = styled(AntTitle)`
  && {
    margin: 46px 32px 69px 32px;
  }
`;

const Wrap = styled.div`
  margin-block: 45px;
`;

const TAB_DEFAULT = EVENT_INFO_TAB;
const VALID_TABS_OLD = [EVENT_INFO_TAB, COMPANY_INFO_TAB];
const VALID_TABS = [EVENT_INFO_TAB, COMPANY_INFO_TAB, SETTINGS_TAB];

export default () => {
  const isProductModularizationEnabled = useSelector(state =>
    isFlagEnabled(state, 'is_product_modularization_enabled')
  );

  const [activeTab, onChange] = useTab(
    TAB_DEFAULT,
    isProductModularizationEnabled ? VALID_TABS : VALID_TABS_OLD,
    false
  );

  return (
    <Wrap>
      <Title>{t`Event Details`}</Title>
      <Tabs
        type="card"
        style={{ overflow: 'visible' }}
        activeKey={activeTab}
        onChange={onChange}
        defaultActiveKey={EVENT_INFO_TAB}
        tabBarStyle={{ marginBottom: 24, paddingLeft: 32 }}
        destroyInactiveTabPane
        items={[
          {
            label: t`Event Info`,
            key: EVENT_INFO_TAB,
            children: isProductModularizationEnabled ? <EventInfo /> : <EventInfoOld />,
          },
          {
            label: t`Company Info`,
            key: COMPANY_INFO_TAB,
            children: <CompanyInfo />,
          },
          isProductModularizationEnabled && {
            label: t`Settings`,
            key: SETTINGS_TAB,
            children: <Settings />,
          },
        ]}
      />
    </Wrap>
  );
};
