import { createContext, useContext } from "react";
export var SIDE_NAV_PROPERTY = "--side-nav-width";
export var SIDE_NAV_WIDTH = {
    Expanded: "230px",
    Collapsed: "48px"
};
export var SIDE_NAV_TRANSITION_CURVE = "0.3s cubic-bezier(0.2, 0, 0, 1)";
export var SideNavExpandedContext = createContext(true);
export var useSideNavExpanded = function() {
    var context = useContext(SideNavExpandedContext);
    if (context === undefined) {
        throw new Error("useSideNavExpanded must be used within a SideNavExpandedProvider");
    }
    return context;
};
