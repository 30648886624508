import { Alert } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

import {
  LIVESTREAM_PLAYBACK_HOURS,
  LIVESTREAM_BROADCAST_HOURS,
  VIDEO_STORAGE_HOURS,
  VIDEO_PLAYBACK_HOURS,
  PEOPLE_CREDITS,
} from '@services/data-usage-service';

import { LEVEL_ERROR, LEVEL_INFO, LEVEL_WARN } from './constants';

const KNOWLEDGE_BASE_URL =
  'https://help.eventmobi.com/en/knowledge/what-happens-when-i-exceed-my-video-limit';

const WARN_MESSAGES = percent => ({
  [LIVESTREAM_BROADCAST_HOURS]: t`Your Organization has used ${percent}% of its Streaming Hours, this does not effect functionality.`,
  [LIVESTREAM_PLAYBACK_HOURS]: t`Your Organization has used ${percent}% of its Live Viewer Hours, this does not effect functionality but additional charges may apply if you go over limit.`,
  [VIDEO_STORAGE_HOURS]: t`Your Organization has used ${percent}% of its Video Hours and you will soon be unable to upload any new videos. Additional charges may apply if you go over limit.`,
  [VIDEO_PLAYBACK_HOURS]: t`Your Organization has used ${percent}% of its On-Demand Viewer Hours and video library content will soon be disabled.`,
  [PEOPLE_CREDITS]: t`Your organization has used ${percent}% of its People Credit. This does not affect functionality, but additional charges may apply if you go over limit.`,
});

const ERROR_MESSAGES = percent => ({
  [LIVESTREAM_BROADCAST_HOURS]: t`Your Organization has used more than its allocated Streaming Hours, this does not effect functionality.`,
  [LIVESTREAM_PLAYBACK_HOURS]: t`Your Organization has used more than its purchased Live Viewer Hours, this does not effect functionality but additional charges may apply.`,
  [VIDEO_STORAGE_HOURS]: t`Your Organization has used more than its purchased Video Hours. You will be unable to upload any new videos and additional charges may apply.`,
  [VIDEO_PLAYBACK_HOURS]: t`Your Organization has used ${percent}% of its On-Demand Viewer Hours and all video library contents have been disabled.`,
  [PEOPLE_CREDITS]: t`Your organization has used all the People Credit. This does not affect functionality, but additional charges may apply.`,
});

const UsageLimitAlert = ({ type, percent, level }) => {
  if (percent < 80 || level === LEVEL_INFO) {
    return null;
  }

  return (
    <Alert
      showIcon
      type={level}
      message={
        <>
          {level === LEVEL_WARN && WARN_MESSAGES(percent)[type]}
          {level === LEVEL_ERROR && ERROR_MESSAGES(percent)[type]}{' '}
          {type !== PEOPLE_CREDITS && (
            <a
              href={KNOWLEDGE_BASE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >{t`Learn more`}</a>
          )}
        </>
      }
    />
  );
};

UsageLimitAlert.propTypes = {
  type: PropTypes.oneOf([
    LIVESTREAM_BROADCAST_HOURS,
    LIVESTREAM_PLAYBACK_HOURS,
    VIDEO_STORAGE_HOURS,
    VIDEO_PLAYBACK_HOURS,
    PEOPLE_CREDITS,
  ]).isRequired,
  percent: PropTypes.number.isRequired,
  level: PropTypes.oneOf([LEVEL_INFO, LEVEL_WARN, LEVEL_ERROR]).isRequired,
};

export default UsageLimitAlert;
