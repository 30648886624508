import apiFn from 'common/services/api-service';

const api = eventId =>
  apiFn(`/api/uapi/events/${eventId}/custom-domain`, {
    Accept: 'application/vnd.eventmobi+json; version=p.5',
  });

export const STATUS_INACTIVE = 'INACTIVE';
export const STATUS_PENDING = 'PENDING';
export const STATUS_VALIDATING = 'PENDING_VALIDATION';
export const STATUS_VALIDATION_TIMEDOUT = 'VALIDATION_TIMED_OUT';
export const STATUS_SUCCESS = 'SUCCESS';
export const STATUS_EXPIRED = 'EXPIRED';
export const STATUS_ERROR = 'ERROR';

export const SUPPORT_EMAIL = 'support@eventmobi.com';

export const SUPPORT_HELP_URL =
  'https://help.eventmobi.com/en/knowledge/can-i-change-my-app-address-or-use-a-custom-domain-1';

export const get = async eventId => {
  return api(eventId)
    .get()
    .then(response => response.data.data);
};

export const create = async (eventId, domain) => {
  return api(eventId)
    .post(null, { customDomain: domain })
    .then(response => response.data.data);
};

export function remove(eventId, id) {
  return api(eventId)
    .delete(id)
    .then(response => response.data.data);
}
