import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Col, Radio, Typography, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { jt, t } from 'ttag';

import { toQuery } from 'common/hooks/query-service';
import { listCmsEvents as listEventsFn } from 'common/services/events/api';
import { getId } from 'common/state/organization';

import usePermission, { CREATE_ORGANIZERS } from '@hooks/usePermission';
import useTab from '@hooks/useTab';

import Active from './Active';
import InviteModal from './InviteModal';
import Pending from './Pending';
import { list as listFn, STATUS_ACTIVE, STATUS_PENDING } from './service';

const Base = styled.div`
  /* accommodate deleteBar component's height */
  margin-bottom: 40px;
`;
const Caption = styled(Typography.Text)`
  margin-right: 8px;
`;

const useApi = fn => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const response = await fn();
      setData(response);
    } finally {
      setLoading(false);
    }
  }, [fn]);

  useEffect(() => {
    load();
  }, [load]);

  return { data, loading, forceReload: load };
};

const TAB_ACTIVE = 'active';
const TAB_PENDING = 'pending';

const VALID_TABS = [TAB_ACTIVE, TAB_PENDING];

const listEventsPayload = {
  sort: {
    order: 'descend',
    field: 'start_date',
  },
};

const Organizers = () => {
  const { authorized } = usePermission();
  const organizationId = useSelector(getId);
  const [activeTab, onChange] = useTab(
    TAB_ACTIVE,
    authorized(CREATE_ORGANIZERS) ? VALID_TABS : [TAB_ACTIVE],
    false
  );
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [organizer, setOrganizer] = useState(null);

  const activeListFn = useCallback(() => listFn(organizationId, STATUS_ACTIVE), [organizationId]);
  const pendingListFn = useCallback(() => listFn(organizationId, STATUS_PENDING), [organizationId]);
  const eventsListFn = useCallback(
    () => listEventsFn(organizationId, undefined, toQuery(listEventsPayload)).then(res => res.data),
    [organizationId]
  );

  const { data: activeList, loading: loadingActive, forceReload: forceReloadActive } = useApi(
    activeListFn
  );
  const { data: pendingList, loading: loadingPending, forceReload: forceReloadPending } = useApi(
    pendingListFn
  );
  const { data: eventsList, loading: loadingEvents } = useApi(eventsListFn);

  useEffect(() => {
    if (organizer) {
      setShowInviteModal(true);
    }
  }, [organizer]);

  const onCloseInviteModal = (reload, isEditMode) => {
    if (reload) {
      if (activeTab === TAB_ACTIVE && isEditMode) {
        forceReloadActive();
      } else {
        forceReloadPending();
      }
    }
    setOrganizer(null);
    setShowInviteModal(false);
  };

  const KNOWLEDGE_BASE_URL =
    'https://help.eventmobi.com/en/knowledge/organizer-permission-controls';

  const faqLink = (
    <a key="learn-more" href={KNOWLEDGE_BASE_URL} target="_blank" rel="noopener noreferrer">
      {t`learn more about organizer roles and permissions here.`}
    </a>
  );

  return (
    <Base>
      <Row css="margin: 20px 0;" justify="space-between">
        {authorized(CREATE_ORGANIZERS) && (
          <>
            <Col>
              <Space direction="vertical">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setShowInviteModal(true)}
                  disabled={loadingEvents}
                >
                  {t`Invite Organizer`}
                </Button>
                <Typography>{jt`You can ${faqLink}`}</Typography>
              </Space>
            </Col>
            <Col>
              <Caption>{t`View:`}</Caption>
              <Radio.Group
                buttonStyle="outline"
                onChange={e => onChange(e.target.value)}
                value={activeTab}
              >
                <Radio.Button value={TAB_ACTIVE}>
                  {t`Active`} ({activeList?.length ?? '-'})
                </Radio.Button>
                <Radio.Button value={TAB_PENDING}>
                  {t`Pending`} ({pendingList?.length ?? '-'})
                </Radio.Button>
              </Radio.Group>
            </Col>
          </>
        )}
      </Row>

      {activeTab === TAB_ACTIVE && !loadingEvents && (
        <Active
          organizationId={organizationId}
          events={eventsList}
          list={activeList ?? []}
          loading={loadingActive || loadingEvents}
          onDelete={forceReloadActive}
          onEdit={setOrganizer}
        />
      )}

      {activeTab === TAB_PENDING && !loadingEvents && (
        <Pending
          organizationId={organizationId}
          events={eventsList}
          list={pendingList ?? []}
          loading={loadingPending || loadingEvents}
          onResend={forceReloadPending}
          onRemove={forceReloadPending}
          onEdit={setOrganizer}
        />
      )}

      {showInviteModal && !loadingEvents && (
        <InviteModal
          organizer={organizer}
          organizationId={organizationId}
          onClose={onCloseInviteModal}
          eventsList={eventsList}
        />
      )}
    </Base>
  );
};

export default Organizers;
