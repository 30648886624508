import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { msgid, ngettext, t } from 'ttag';

import { useNoContractEvents } from '@domains/Organization/queries';

const useContractAlerts = () => {
  const { organizationId } = useParams();
  const { isLoading, data } = useNoContractEvents(organizationId);
  const ManageEventLink = (
    <Link to={`/organization/${organizationId}/events`}>{t`Manage Events`}</Link>
  );

  if (isLoading || data.length === 0) {
    return { isLoading, alerts: [] };
  }

  return {
    isLoading: false,
    alerts: [
      {
        id: 'emailDomain',

        content: [
          ngettext(
            msgid`${data.length} of your events has dates outside the contract period and will not be accessible to attendees during those dates. `,
            `${data.length} of your events have dates outside the contract period and will not be accessible to attendees during those dates. `,
            data.length
          ),
          ManageEventLink,
        ],
        type: 'error',
        scope: 'organization',
      },
    ],
  };
};

export default useContractAlerts;
