import { CopyOutlined, LeftOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Divider,
  Form,
  Input as AntInput,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Steps,
  Tooltip,
  Typography,
} from 'antd';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { jt, t } from 'ttag';

import Label from 'common/components/Label';
import Url, { UrlTypes } from 'common/components/Url';

import FileString from '@components/FileString';

import { ERROR_MESSAGES_BY_CODE, IDP_TYPES, IDP_TYPE_OIDC, IDP_TYPE_SAML } from './constants';
import { create as createFn } from './service';

const STEP_IDENTITY_PROVIDER = 0;
const STEP_MAP_ATTRIBUTES = 1;
const STEP_LINK_EVENTS = 2;

const DEFAULT_IDP_TYPE = IDP_TYPE_SAML;

const Field = styled(Form.Item)`
  && {
    max-width: 716px;
    margin-bottom: 15px;
    ${props => props.$indented && 'margin-left: 10px'}
  }
`;

const Input = styled(AntInput)`
  && {
    ${props => props.$withIcon && `width: calc(100% - 31px);`}
  }
`;

const { useForm } = Form;

const KNOWLEDGE_BASE_URL =
  'https://help.eventmobi.com/en/knowledge/can-i-set-up-single-sign-on-sso-with-my-identity-provider';

const METADATA_DOC_URL = 'metadata_url';
const METADATA_DOC_FILE = 'metadata_file';

// eslint-disable-next-line react/prop-types
const IdentityProviderForm = ({ visible, sso }) => {
  const [type, setType] = useState(DEFAULT_IDP_TYPE);
  const [metadataDocType, setMetadataDocType] = useState(METADATA_DOC_URL);
  const [form] = useForm();
  const { setFieldValue, validateFields } = form;

  const copy = async (title, text) => {
    await navigator.clipboard.writeText(text);
    message.success(t`${title} copied to clipboard!`);
  };

  const learnMore = (
    <a
      key="learnmore"
      href={KNOWLEDGE_BASE_URL}
      target="_blank"
      rel="noopener noreferrer"
    >{t`Learn more`}</a>
  );
  const entityIdText = <strong key="entityId">{t`URN/Entity ID`}</strong>;
  const idpResponseURLText = <strong key="idpResponseURL">{t`IDP Response URL`}</strong>;
  const descriptions = {
    [IDP_TYPE_OIDC]: jt`Please create an application within your Identity Provider using the ${idpResponseURLText} before configuring the SSO connection in Experience Manager. ${learnMore}`,
    [IDP_TYPE_SAML]: jt`Please create an application within your Identity Provider using the ${entityIdText} and ${idpResponseURLText} before configuring the SSO connection in Experience Manager. ${learnMore}`,
  };

  return (
    <Form
      form={form}
      name="identityProvider"
      hidden={!visible}
      layout="vertical"
      requiredMark={false}
      initialValues={{ type: DEFAULT_IDP_TYPE }}
    >
      <Field
        name="type"
        label={
          <Label isRequired>{t`Which identity provider type you want to proceed with?`}</Label>
        }
      >
        <Radio.Group onChange={e => setType(e.target.value)}>
          {Object.entries(IDP_TYPES()).map(([value, text]) => (
            <Radio.Button key={value} value={value}>
              {text}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Field>

      {type === IDP_TYPE_OIDC && (
        <Field label={<Label>{t`IDP Response URL`}</Label>}>
          <Input.Group compact>
            <Input $withIcon disabled value={sso.oidcSignOnUrl} />
            <Tooltip title={t`Copy to clipboard`}>
              <Button
                icon={<CopyOutlined />}
                onClick={() => copy(t`IDP Response URL`, sso.oidcSignOnUrl)}
              />
            </Tooltip>
          </Input.Group>
        </Field>
      )}

      {type === IDP_TYPE_SAML && (
        <>
          <Field label={<Label>{t`URN / Entity ID`}</Label>}>
            <Input.Group compact>
              <Input $withIcon disabled value={sso.audienceUri} />
              <Tooltip title={t`Copy to clipboard`}>
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => copy(t`URN / Entity ID`, sso.audienceUri)}
                />
              </Tooltip>
            </Input.Group>
          </Field>
          <Field label={<Label>{t`IDP Response URL`}</Label>}>
            <Input.Group compact>
              <Input $withIcon disabled value={sso.samlSignOnUrl} />
              <Tooltip title={t`Copy to clipboard`}>
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => copy(t`IDP Response URL`, sso.samlSignOnUrl)}
                />
              </Tooltip>
            </Input.Group>
          </Field>
        </>
      )}

      <Alert showIcon message={t`Set up on Identity Provider`} description={descriptions[type]} />

      <Divider />

      <Typography.Title level={5}>{t`Add Identity Provider`}</Typography.Title>

      <Field
        $indented
        name="displayName"
        label={<Label isRequired>{t`Connection Name`}</Label>}
        rules={[
          { required: true, message: t`Connection Name is required.` },
          { max: 35, message: t`Maximum of 35 characters.` },
        ]}
      >
        <Input placeholder={t`Enter connection name with up to 35 characters`} />
      </Field>

      {type === IDP_TYPE_OIDC && (
        <>
          <Field
            $indented
            name={['oidcDetails', 'clientId']}
            label={<Label isRequired>{t`Client ID`}</Label>}
            rules={[{ required: true, message: t`Client ID is required.` }]}
          >
            <Input />
          </Field>
          <Field
            $indented
            name={['oidcDetails', 'clientSecret']}
            label={<Label isRequired>{t`Client Secret`}</Label>}
            rules={[{ required: true, message: t`Client Secret is required.` }]}
          >
            <Input type="password" />
          </Field>
          <Field
            $indented
            name={['oidcDetails', 'attributesRequestMethod']}
            label={<Label isRequired>{t`Request Method`}</Label>}
            rules={[{ required: true, message: t`Request Method is required.` }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="GET">GET</Radio>
                <Radio value="POST">POST</Radio>
              </Space>
            </Radio.Group>
          </Field>
          <Field $indented>
            <Url
              required
              requiredMessage={t`Issuer is required.`}
              name={['oidcDetails', 'oidcIssuer']}
              type={UrlTypes.Website}
              httpsOnly
              label={t`Issuer`}
              setFieldValue={setFieldValue}
              validateFields={validateFields}
            />
          </Field>
        </>
      )}

      {type === IDP_TYPE_SAML && (
        <>
          <Field $indented label={<Label isRequired>{t`Metadata Document Source`}</Label>}>
            <Radio.Group
              style={{ width: '100%' }}
              onChange={e => setMetadataDocType(e.target.value)}
              value={metadataDocType}
            >
              <Space direction="vertical" style={{ width: '100%' }}>
                <Radio value={METADATA_DOC_URL}>
                  <Typography.Text strong>{t`Metadata Document Endpoint URL`}</Typography.Text>
                </Radio>
                {metadataDocType === METADATA_DOC_URL && (
                  <Field>
                    <Url
                      required
                      requiredMessage={t`Metadata Document Endpoint URL is required.`}
                      name={['samlDetails', 'metadataUrl']}
                      type={UrlTypes.Website}
                      setFieldValue={setFieldValue}
                      validateFields={validateFields}
                    />
                  </Field>
                )}

                <Radio value={METADATA_DOC_FILE}>
                  <Typography.Text strong>{t`Upload Metadata Document`}</Typography.Text>
                </Radio>
                {metadataDocType === METADATA_DOC_FILE && (
                  <Field
                    name={['samlDetails', 'metadataFile']}
                    rules={[{ required: true, message: t`Metadata Document is required.` }]}
                  >
                    <FileString supportedTypes={['.xml']} />
                  </Field>
                )}
              </Space>
            </Radio.Group>
          </Field>
        </>
      )}
    </Form>
  );
};

// eslint-disable-next-line react/prop-types
const MapAttributesForm = ({ visible }) => {
  return (
    <Form name="mapAttributes" hidden={!visible} layout="vertical" requiredMark={false}>
      <Typography.Title level={5}>{t`Add Identity Provider`}</Typography.Title>
      <Typography.Paragraph>
        {t`Your required attributes are mapped to the equivalent  attributes. Each attritube you add must be mapped to the attribute of the Identity Provider type you selected.`}
      </Typography.Paragraph>

      <Field
        $indented
        name={['attributesMappings', 'givenNameMapping']}
        label={<Label isRequired>{t`First Name`}</Label>}
        rules={[{ required: true, message: t`First Name mapping is required.` }]}
      >
        <Input placeholder="first_name or given_name" />
      </Field>
      <Field
        $indented
        name={['attributesMappings', 'familyNameMapping']}
        label={<Label isRequired>{t`Last Name`}</Label>}
        rules={[{ required: true, message: t`Last Name mapping is required.` }]}
      >
        <Input placeholder="last_name or family_name" />
      </Field>
      <Field
        $indented
        name={['attributesMappings', 'emailMapping']}
        label={<Label isRequired>{t`Email`}</Label>}
        rules={[{ required: true, message: t`Email mapping is required.` }]}
      >
        <Input placeholder="email" />
      </Field>
    </Form>
  );
};

// eslint-disable-next-line react/prop-types
const LinkEventsForm = ({ visible, sso }) => {
  return (
    <Form
      name="linkEvents"
      hidden={!visible}
      layout="vertical"
      requiredMark={false}
      initialValues={{ type: IDP_TYPE_OIDC }}
    >
      <Typography.Title level={5}>{t`Link Single Sign-On to Events`}</Typography.Title>
      <Typography.Paragraph>
        {t`Only events with 'Single Sign-On' feature activated will be shown as selection.`}
      </Typography.Paragraph>

      <Field
        $indented
        name="events"
        label={<Label>{t`Event Codes`}</Label>}
        rules={[{ type: 'array' }]}
        normalize={ids => ids.map(id => ({ id }))}
        getValueProps={events => ({
          value: (events ?? []).sort((a, b) => a.order - b.order).map(({ id }) => id),
        })}
      >
        <Select
          mode="multiple"
          options={sso.ssoEnabledEvents.map(event => ({ label: event.name, value: event.id }))}
        />
      </Field>
    </Form>
  );
};

const AddModal = ({ organizationId, sso, onClose = () => {} }) => {
  const [step, setStep] = useState(STEP_IDENTITY_PROVIDER);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = useForm();

  const handleNextStep = async (_, { forms }) => {
    switch (step) {
      case STEP_IDENTITY_PROVIDER:
        await forms.identityProvider.validateFields();
        setStep(STEP_MAP_ATTRIBUTES);
        return;
      case STEP_MAP_ATTRIBUTES:
        await forms.mapAttributes.validateFields();
        setStep(STEP_LINK_EVENTS);
        return;
      case STEP_LINK_EVENTS: {
        await forms.linkEvents.validateFields();

        const allValues = await Promise.all(
          Object.values(forms).map(f => f.validateFields().then(values => values))
        );
        const payload = merge({}, ...allValues);

        try {
          setError(null);
          setLoading(true);
          await createFn(organizationId, payload);

          onClose(true);
          message.success(t`SSO Connection added successfully.`);
        } catch (err) {
          setError(
            ERROR_MESSAGES_BY_CODE()[err.errors?.[0]?.code] ??
              t`Unknown error occurred while saving SSO connection.`
          );
          setStep(STEP_IDENTITY_PROVIDER);
          setLoading(false);
        }
        return;
      }
      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <Modal
      title={t`Add SSO Connection`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={() => onClose(false)}
      footer={[
        step !== STEP_IDENTITY_PROVIDER && (
          <Button
            key="back"
            icon={<LeftOutlined />}
            onClick={() => setStep(step - 1)}
          >{t`Back`}</Button>
        ),
        <Button key="cancel" onClick={() => onClose(false)}>{t`Cancel`}</Button>,
        <Button key="next" type="primary" onClick={() => form.submit()} loading={loading}>
          {step !== STEP_LINK_EVENTS ? t`Next` : t`Save`}
        </Button>,
      ]}
      width={700}
    >
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Steps
          size="small"
          current={step}
          items={[
            {
              title: t`Add Identity Provider`,
            },
            {
              title: t`Map Attributes`,
            },
            {
              title: t`Link to Events`,
            },
          ]}
        />

        {error && <Alert showIcon type="error" message={error} />}

        <Form.Provider onFormFinish={handleNextStep}>
          <IdentityProviderForm visible={step === STEP_IDENTITY_PROVIDER} sso={sso} />
          <MapAttributesForm visible={step === STEP_MAP_ATTRIBUTES} />
          <LinkEventsForm visible={step === STEP_LINK_EVENTS} sso={sso} />
          <Form form={form} />
        </Form.Provider>
      </Space>
    </Modal>
  );
};

AddModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  sso: PropTypes.shape({
    oidcSignOnUrl: PropTypes.string.isRequired,
    samlSignOnUrl: PropTypes.string.isRequired,
    audienceUri: PropTypes.string.isRequired,
    ssoEnabledEvents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }),
  onClose: PropTypes.func,
};

export default AddModal;
