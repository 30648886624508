import apiFn from 'common/services/api-service';

const api = organizationId =>
  apiFn(`/api/uapi/organizations/${organizationId}/custom-domains`, {
    Accept: 'application/vnd.eventmobi+json; version=p.7',
  });

export const list = (organizationId, payload = {}) => {
  const params = new URLSearchParams(payload);

  params.set('include', 'linked_events');

  return api(organizationId)
    .get(null, { params })
    .then(response => response.data);
};

export const get = (organizationId, id) =>
  api(organizationId)
    .get(id, { params: { include: 'dns_records' } })
    .then(response => response.data);

export const verify = (organizationId, id) =>
  api(organizationId)
    .post(`${id}/verify`)
    .then(response => response.data);

export const create = (organizationId, payload) =>
  api(organizationId)
    .post(null, payload)
    .then(response => response.data);

export const remove = (organizationId, id) =>
  api(organizationId)
    .delete(id)
    .then(response => response.data);

export const linkEvents = (organizationId, id, linkedEvents, rootDomainRedirectUrl = undefined) =>
  api(organizationId)
    .patch(id, { linkedEvents, rootDomainRedirectUrl: rootDomainRedirectUrl || undefined })
    .then(response => response.data);
