import { t } from 'ttag';

// source: https://github.com/dr5hn/countries-states-cities-database

// https://github.com/dr5hn/countries-states-cities-database/blob/master/json/countries%2Bstates%2Bcities.json

const countries = () => [
  {
    emoji: '🇦🇫',
    id: 1,
    iso2: 'AF',
    iso3: 'AFG',
    name: 'Afghanistan',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇦🇽',
    id: 2,
    iso2: 'AX',
    iso3: 'ALA',
    name: 'Aland Islands',
    type: t`Municipalities`,
    postalTerm: 'Postnummer',
  },
  {
    emoji: '🇦🇱',
    id: 3,
    iso2: 'AL',
    iso3: 'ALB',
    name: 'Albania',
    postalTerm: 'Kodi Postar',
  },
  {
    emoji: '🇩🇿',
    id: 4,
    iso2: 'DZ',
    iso3: 'DZA',
    name: 'Algeria',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇦🇸',
    id: 5,
    iso2: 'AS',
    iso3: 'ASM',
    name: 'American Samoa',
    postalTerm: 'ZIP Code',
  },
  {
    emoji: '🇦🇩',
    id: 6,
    iso2: 'AD',
    iso3: 'AND',
    name: 'Andorra',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇦🇴',
    id: 7,
    iso2: 'AO',
    iso3: 'AGO',
    name: 'Angola',
  },
  {
    emoji: '🇦🇮',
    id: 8,
    iso2: 'AI',
    iso3: 'AIA',
    name: 'Anguilla',
    type: t`District`,
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇦🇶',
    id: 9,
    iso2: 'AQ',
    iso3: 'ATA',
    name: 'Antarctica',
  },
  {
    emoji: '🇦🇬',
    id: 10,
    iso2: 'AG',
    iso3: 'ATG',
    name: 'Antigua and Barbuda',
  },
  {
    emoji: '🇦🇷',
    id: 11,
    iso2: 'AR',
    iso3: 'ARG',
    name: 'Argentina',
    type: t`Province`,
    postalTerm: 'Cpa',
  },
  {
    emoji: '🇦🇲',
    id: 12,
    iso2: 'AM',
    iso3: 'ARM',
    name: 'Armenia',
    postalTerm: 'Փոստային Ինդեքս',
  },
  {
    emoji: '🇦🇼',
    id: 13,
    iso2: 'AW',
    iso3: 'ABW',
    name: 'Aruba',
    type: t`Regions`,
  },
  {
    emoji: '🇦🇺',
    id: 14,
    iso2: 'AU',
    iso3: 'AUS',
    name: 'Australia',
    type: t`Territory`,
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇦🇹',
    id: 15,
    iso2: 'AT',
    iso3: 'AUT',
    name: 'Austria',
    postalTerm: 'PLZ',
  },
  {
    emoji: '🇦🇿',
    id: 16,
    iso2: 'AZ',
    iso3: 'AZE',
    name: 'Azerbaijan',
    postalTerm: 'Poçt Indeksi',
  },
  {
    emoji: '🇧🇭',
    id: 17,
    iso2: 'BH',
    iso3: 'BHR',
    name: 'Bahrain',
    postalTerm: 'Block',
  },
  {
    emoji: '🇧🇩',
    id: 18,
    iso2: 'BD',
    iso3: 'BGD',
    name: 'Bangladesh',
    type: t`Division`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇧🇧',
    id: 19,
    iso2: 'BB',
    iso3: 'BRB',
    name: 'Barbados',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇧🇾',
    id: 20,
    iso2: 'BY',
    iso3: 'BLR',
    name: 'Belarus',
    postalTerm: 'Паштовы Індэкс',
  },
  {
    emoji: '🇧🇪',
    id: 21,
    iso2: 'BE',
    iso3: 'BEL',
    name: 'Belgium',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇧🇿',
    id: 22,
    iso2: 'BZ',
    iso3: 'BLZ',
    name: 'Belize',
  },
  {
    emoji: '🇧🇯',
    id: 23,
    iso2: 'BJ',
    iso3: 'BEN',
    name: 'Benin',
  },
  {
    emoji: '🇧🇲',
    id: 24,
    iso2: 'BM',
    iso3: 'BMU',
    name: 'Bermuda',
    type: t`Municipality`,
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇧🇹',
    id: 25,
    iso2: 'BT',
    iso3: 'BTN',
    name: 'Bhutan',
    type: t`District`,
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇧🇴',
    id: 26,
    iso2: 'BO',
    iso3: 'BOL',
    name: 'Bolivia',
  },
  {
    emoji: '🇧🇶',
    id: 27,
    iso2: 'BQ',
    iso3: 'BES',
    name: 'Bonaire, Sint Eustatius and Saba',
    type: t`Special Municipality`,
  },
  {
    emoji: '🇧🇦',
    id: 28,
    iso2: 'BA',
    iso3: 'BIH',
    name: 'Bosnia and Herzegovina',
    postalTerm: 'Poštanski Broj',
  },
  {
    emoji: '🇧🇼',
    id: 29,
    iso2: 'BW',
    iso3: 'BWA',
    name: 'Botswana',
  },
  {
    emoji: '🇧🇻',
    id: 30,
    iso2: 'BV',
    iso3: 'BVT',
    name: 'Bouvet Island',
    postalTerm: 'Postnummer',
  },
  {
    emoji: '🇧🇷',
    id: 31,
    iso2: 'BR',
    iso3: 'BRA',
    name: 'Brazil',
    postalTerm: 'CEP',
  },
  {
    emoji: '🇮🇴',
    id: 32,
    iso2: 'IO',
    iso3: 'IOT',
    name: 'British Indian Ocean Territory',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇧🇳',
    id: 33,
    iso2: 'BN',
    iso3: 'BRN',
    name: 'Brunei',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇧🇬',
    id: 34,
    iso2: 'BG',
    iso3: 'BGR',
    name: 'Bulgaria',
    postalTerm: 'Пощенски Код',
  },
  {
    emoji: '🇧🇫',
    id: 35,
    iso2: 'BF',
    iso3: 'BFA',
    name: 'Burkina Faso',
  },
  {
    emoji: '🇧🇮',
    id: 36,
    iso2: 'BI',
    iso3: 'BDI',
    name: 'Burundi',
  },
  {
    emoji: '🇰🇭',
    id: 37,
    iso2: 'KH',
    iso3: 'KHM',
    name: 'Cambodia',
    type: t`Province`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇨🇲',
    id: 38,
    iso2: 'CM',
    iso3: 'CMR',
    name: 'Cameroon',
  },
  {
    emoji: '🇨🇦',
    id: 39,
    iso2: 'CA',
    iso3: 'CAN',
    name: 'Canada',
    type: t`Province`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇨🇻',
    id: 40,
    iso2: 'CV',
    iso3: 'CPV',
    name: 'Cape Verde',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇰🇾',
    id: 41,
    iso2: 'KY',
    iso3: 'CYM',
    name: 'Cayman Islands',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇨🇫',
    id: 42,
    iso2: 'CF',
    iso3: 'CAF',
    name: 'Central African Republic',
  },
  {
    emoji: '🇹🇩',
    id: 43,
    iso2: 'TD',
    iso3: 'TCD',
    name: 'Chad',
    type: t`Province`,
  },
  {
    emoji: '🇨🇱',
    id: 44,
    iso2: 'CL',
    iso3: 'CHL',
    name: 'Chile',
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇨🇳',
    id: 45,
    iso2: 'CN',
    iso3: 'CHN',
    name: 'China',
    type: t`Province`,
    postalTerm: '邮政编码',
  },
  {
    emoji: '🇨🇽',
    id: 46,
    iso2: 'CX',
    iso3: 'CXR',
    name: 'Christmas Island',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇨🇨',
    id: 47,
    iso2: 'CC',
    iso3: 'CCK',
    name: 'Cocos (Keeling) Islands',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇨🇴',
    id: 48,
    iso2: 'CO',
    iso3: 'COL',
    name: 'Colombia',
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇰🇲',
    id: 49,
    iso2: 'KM',
    iso3: 'COM',
    name: 'Comoros',
  },
  {
    emoji: '🇨🇬',
    id: 50,
    iso2: 'CG',
    iso3: 'COG',
    name: 'Congo',
  },
  {
    emoji: '🇨🇰',
    id: 51,
    iso2: 'CK',
    iso3: 'COK',
    name: 'Cook Islands',
  },
  {
    emoji: '🇨🇷',
    id: 52,
    iso2: 'CR',
    iso3: 'CRI',
    name: 'Costa Rica',
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇨🇮',
    id: 53,
    iso2: 'CI',
    iso3: 'CIV',
    name: "Cote D'Ivoire (Ivory Coast)",
  },
  {
    emoji: '🇭🇷',
    id: 54,
    iso2: 'HR',
    iso3: 'HRV',
    name: 'Croatia',
    type: t`County`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇨🇺',
    id: 55,
    iso2: 'CU',
    iso3: 'CUB',
    name: 'Cuba',
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇨🇼',
    id: 56,
    iso2: 'CW',
    iso3: 'CUW',
    name: 'Curaçao',
  },
  {
    emoji: '🇨🇾',
    id: 57,
    iso2: 'CY',
    iso3: 'CYP',
    name: 'Cyprus',
    type: t`District`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇨🇿',
    id: 58,
    iso2: 'CZ',
    iso3: 'CZE',
    name: 'Czech Republic',
    postalTerm: 'PSČ',
  },
  {
    emoji: '🇨🇩',
    id: 59,
    iso2: 'CD',
    iso3: 'COD',
    name: 'Democratic Republic of the Congo',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇩🇰',
    id: 60,
    iso2: 'DK',
    iso3: 'DNK',
    name: 'Denmark',
    postalTerm: 'Postnummer',
  },
  {
    emoji: '🇩🇯',
    id: 61,
    iso2: 'DJ',
    iso3: 'DJI',
    name: 'Djibouti',
  },
  {
    emoji: '🇩🇲',
    id: 62,
    iso2: 'DM',
    iso3: 'DMA',
    name: 'Dominica',
  },
  {
    emoji: '🇩🇴',
    id: 63,
    iso2: 'DO',
    iso3: 'DOM',
    name: 'Dominican Republic',
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇪🇨',
    id: 64,
    iso2: 'EC',
    iso3: 'ECU',
    name: 'Ecuador',
    type: t`Province`,
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇪🇬',
    id: 65,
    iso2: 'EG',
    iso3: 'EGY',
    name: 'Egypt',
    postalTerm: 'الرمز البريدى',
  },
  {
    emoji: '🇸🇻',
    id: 66,
    iso2: 'SV',
    iso3: 'SLV',
    name: 'El Salvador',
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇬🇶',
    id: 67,
    iso2: 'GQ',
    iso3: 'GNQ',
    name: 'Equatorial Guinea',
  },
  {
    emoji: '🇪🇷',
    id: 68,
    iso2: 'ER',
    iso3: 'ERI',
    name: 'Eritrea',
  },
  {
    emoji: '🇪🇪',
    id: 69,
    iso2: 'EE',
    iso3: 'EST',
    name: 'Estonia',
    postalTerm: 'Postiindeks',
  },
  {
    emoji: '🇸🇿',
    id: 70,
    iso2: 'SZ',
    iso3: 'SWZ',
    name: 'Eswatini',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇪🇹',
    id: 71,
    iso2: 'ET',
    iso3: 'ETH',
    name: 'Ethiopia',
  },
  {
    emoji: '🇫🇰',
    id: 72,
    iso2: 'FK',
    iso3: 'FLK',
    name: 'Falkland Islands',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇫🇴',
    id: 73,
    iso2: 'FO',
    iso3: 'FRO',
    name: 'Faroe Islands',
    type: t`Region`,
    postalTerm: 'Postnummer',
  },
  {
    emoji: '🇫🇯',
    id: 74,
    iso2: 'FJ',
    iso3: 'FJI',
    name: 'Fiji Islands',
  },
  {
    emoji: '🇫🇮',
    id: 75,
    iso2: 'FI',
    iso3: 'FIN',
    name: 'Finland',
    type: t`Region`,
    postalTerm: 'Postinumero',
  },
  {
    emoji: '🇫🇷',
    id: 76,
    iso2: 'FR',
    iso3: 'FRA',
    name: 'France',
    type: t`Metropolitan Department`,
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇬🇫',
    id: 77,
    iso2: 'GF',
    iso3: 'GUF',
    name: 'French Guiana',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇵🇫',
    id: 78,
    iso2: 'PF',
    iso3: 'PYF',
    name: 'French Polynesia',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇹🇫',
    id: 79,
    iso2: 'TF',
    iso3: 'ATF',
    name: 'French Southern Territories',
  },
  {
    emoji: '🇬🇦',
    id: 80,
    iso2: 'GA',
    iso3: 'GAB',
    name: 'Gabon',
  },
  {
    emoji: '🇬🇪',
    id: 81,
    iso2: 'GE',
    iso3: 'GEO',
    name: 'Georgia',
    postalTerm: 'Საფოსტო Ინდექსის',
  },
  {
    emoji: '🇩🇪',
    id: 82,
    iso2: 'DE',
    iso3: 'DEU',
    name: 'Germany',
    postalTerm: 'PLZ',
  },
  {
    emoji: '🇬🇭',
    id: 83,
    iso2: 'GH',
    iso3: 'GHA',
    name: 'Ghana',
    type: t`Region`,
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇬🇮',
    id: 84,
    iso2: 'GI',
    iso3: 'GIB',
    name: 'Gibraltar',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇬🇷',
    id: 85,
    iso2: 'GR',
    iso3: 'GRC',
    name: 'Greece',
    postalTerm: 'ΤΚ',
  },
  {
    emoji: '🇬🇱',
    id: 86,
    iso2: 'GL',
    iso3: 'GRL',
    name: 'Greenland',
    postalTerm: 'Postnummer',
  },
  {
    emoji: '🇬🇩',
    id: 87,
    iso2: 'GD',
    iso3: 'GRD',
    name: 'Grenada',
  },
  {
    emoji: '🇬🇵',
    id: 88,
    iso2: 'GP',
    iso3: 'GLP',
    name: 'Guadeloupe',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇬🇺',
    id: 89,
    iso2: 'GU',
    iso3: 'GUM',
    name: 'Guam',
    type: t`Village`,
    postalTerm: 'ZIP Code',
  },
  {
    emoji: '🇬🇹',
    id: 90,
    iso2: 'GT',
    iso3: 'GTM',
    name: 'Guatemala',
    type: t`Department`,
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇬🇬',
    id: 91,
    iso2: 'GG',
    iso3: 'GGY',
    name: 'Guernsey and Alderney',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇬🇳',
    id: 92,
    iso2: 'GN',
    iso3: 'GIN',
    name: 'Guinea',
  },
  {
    emoji: '🇬🇼',
    id: 93,
    iso2: 'GW',
    iso3: 'GNB',
    name: 'Guinea-Bissau',
  },
  {
    emoji: '🇬🇾',
    id: 94,
    iso2: 'GY',
    iso3: 'GUY',
    name: 'Guyana',
  },
  {
    emoji: '🇭🇹',
    id: 95,
    iso2: 'HT',
    iso3: 'HTI',
    name: 'Haiti',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇭🇲',
    id: 96,
    iso2: 'HM',
    iso3: 'HMD',
    name: 'Heard Island and McDonald Islands',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇭🇳',
    id: 97,
    iso2: 'HN',
    iso3: 'HND',
    name: 'Honduras',
  },
  {
    emoji: '🇭🇰',
    id: 98,
    iso2: 'HK',
    iso3: 'HKG',
    name: 'Hong Kong S.A.R.',
    type: t`District`,
  },
  {
    emoji: '🇭🇺',
    id: 99,
    iso2: 'HU',
    iso3: 'HUN',
    name: 'Hungary',
    type: t`County`,
    postalTerm: 'Irányítószám',
  },
  {
    emoji: '🇮🇸',
    id: 100,
    iso2: 'IS',
    iso3: 'ISL',
    name: 'Iceland',
    postalTerm: 'Póstnúmer',
  },
  {
    emoji: '🇮🇳',
    id: 101,
    iso2: 'IN',
    iso3: 'IND',
    name: 'India',
    type: t`Union Territory`,
    postalTerm: 'PIN Code',
  },
  {
    emoji: '🇮🇩',
    id: 102,
    iso2: 'ID',
    iso3: 'IDN',
    name: 'Indonesia',
    type: t`Province`,
    postalTerm: 'Kodhe Pos',
  },
  {
    emoji: '🇮🇷',
    id: 103,
    iso2: 'IR',
    iso3: 'IRN',
    name: 'Iran',
    type: t`Province`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇮🇶',
    id: 104,
    iso2: 'IQ',
    iso3: 'IRQ',
    name: 'Iraq',
    type: t`Governorate`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇮🇪',
    id: 105,
    iso2: 'IE',
    iso3: 'IRL',
    name: 'Ireland',
    type: t`County`,
    postalTerm: 'Eircode',
  },
  {
    emoji: '🇮🇱',
    id: 106,
    iso2: 'IL',
    iso3: 'ISR',
    name: 'Israel',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇮🇹',
    id: 107,
    iso2: 'IT',
    iso3: 'ITA',
    name: 'Italy',
    type: t`Region`,
    postalTerm: 'CAP',
  },
  {
    emoji: '🇯🇲',
    id: 108,
    iso2: 'JM',
    iso3: 'JAM',
    name: 'Jamaica',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇯🇵',
    id: 109,
    iso2: 'JP',
    iso3: 'JPN',
    name: 'Japan',
    postalTerm: '郵便番号',
  },
  {
    emoji: '🇯🇪',
    id: 110,
    iso2: 'JE',
    iso3: 'JEY',
    name: 'Jersey',
    type: t`Parishes`,
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇯🇴',
    id: 111,
    iso2: 'JO',
    iso3: 'JOR',
    name: 'Jordan',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇰🇿',
    id: 112,
    iso2: 'KZ',
    iso3: 'KAZ',
    name: 'Kazakhstan',
    postalTerm: 'Пошта Индексі',
  },
  {
    emoji: '🇰🇪',
    id: 113,
    iso2: 'KE',
    iso3: 'KEN',
    name: 'Kenya',
    type: t`County`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇰🇮',
    id: 114,
    iso2: 'KI',
    iso3: 'KIR',
    name: 'Kiribati',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇽🇰',
    id: 115,
    iso2: 'XK',
    iso3: 'XKX',
    name: 'Kosovo',
    postalTerm: 'ZIP Codes',
  },
  {
    emoji: '🇰🇼',
    id: 116,
    iso2: 'KW',
    iso3: 'KWT',
    name: 'Kuwait',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇰🇬',
    id: 117,
    iso2: 'KG',
    iso3: 'KGZ',
    name: 'Kyrgyzstan',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇱🇦',
    id: 118,
    iso2: 'LA',
    iso3: 'LAO',
    name: 'Laos',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇱🇻',
    id: 119,
    iso2: 'LV',
    iso3: 'LVA',
    name: 'Latvia',
    postalTerm: 'Pasta Indekss',
  },
  {
    emoji: '🇱🇧',
    id: 120,
    iso2: 'LB',
    iso3: 'LBN',
    name: 'Lebanon',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇱🇸',
    id: 121,
    iso2: 'LS',
    iso3: 'LSO',
    name: 'Lesotho',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇱🇷',
    id: 122,
    iso2: 'LR',
    iso3: 'LBR',
    name: 'Liberia',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇱🇾',
    id: 123,
    iso2: 'LY',
    iso3: 'LBY',
    name: 'Libya',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇱🇮',
    id: 124,
    iso2: 'LI',
    iso3: 'LIE',
    name: 'Liechtenstein',
    postalTerm: 'PLZ',
  },
  {
    emoji: '🇱🇹',
    id: 125,
    iso2: 'LT',
    iso3: 'LTU',
    name: 'Lithuania',
    postalTerm: 'Pašto Kodas',
  },
  {
    emoji: '🇱🇺',
    id: 126,
    iso2: 'LU',
    iso3: 'LUX',
    name: 'Luxembourg',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇲🇴',
    id: 127,
    iso2: 'MO',
    iso3: 'MAC',
    name: 'Macau S.A.R.',
  },
  {
    emoji: '🇲🇬',
    id: 128,
    iso2: 'MG',
    iso3: 'MDG',
    name: 'Madagascar',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇲🇼',
    id: 129,
    iso2: 'MW',
    iso3: 'MWI',
    name: 'Malawi',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇲🇾',
    id: 130,
    iso2: 'MY',
    iso3: 'MYS',
    name: 'Malaysia',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇲🇻',
    id: 131,
    iso2: 'MV',
    iso3: 'MDV',
    name: 'Maldives',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇲🇱',
    id: 132,
    iso2: 'ML',
    iso3: 'MLI',
    name: 'Mali',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇲🇹',
    id: 133,
    iso2: 'MT',
    iso3: 'MLT',
    name: 'Malta',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇮🇲',
    id: 134,
    iso2: 'IM',
    iso3: 'IMN',
    name: 'Man (Isle of)',
    type: t`Sheadings`,
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇲🇭',
    id: 135,
    iso2: 'MH',
    iso3: 'MHL',
    name: 'Marshall Islands',
    postalTerm: 'ZIP Code',
  },
  {
    emoji: '🇲🇶',
    id: 136,
    iso2: 'MQ',
    iso3: 'MTQ',
    name: 'Martinique',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇲🇷',
    id: 137,
    iso2: 'MR',
    iso3: 'MRT',
    name: 'Mauritania',
    type: t`Region`,
  },
  {
    emoji: '🇲🇺',
    id: 138,
    iso2: 'MU',
    iso3: 'MUS',
    name: 'Mauritius',
    type: t`Dependency`,
    postalTerm: 'Post Code',
  },
  {
    emoji: '🇾🇹',
    id: 139,
    iso2: 'YT',
    iso3: 'MYT',
    name: 'Mayotte',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇲🇽',
    id: 140,
    iso2: 'MX',
    iso3: 'MEX',
    name: 'Mexico',
    type: t`State`,
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇫🇲',
    id: 141,
    iso2: 'FM',
    iso3: 'FSM',
    name: 'Micronesia',
    postalTerm: 'ZIP Code',
  },
  {
    emoji: '🇲🇩',
    id: 142,
    iso2: 'MD',
    iso3: 'MDA',
    name: 'Moldova',
    postalTerm: 'Coduri Poștale',
  },
  {
    emoji: '🇲🇨',
    id: 143,
    iso2: 'MC',
    iso3: 'MCO',
    name: 'Monaco',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇲🇳',
    id: 144,
    iso2: 'MN',
    iso3: 'MNG',
    name: 'Mongolia',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇲🇪',
    id: 145,
    iso2: 'ME',
    iso3: 'MNE',
    name: 'Montenegro',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇲🇸',
    id: 146,
    iso2: 'MS',
    iso3: 'MSR',
    name: 'Montserrat',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇲🇦',
    id: 147,
    iso2: 'MA',
    iso3: 'MAR',
    name: 'Morocco',
    type: t`Prefecture`,
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇲🇿',
    id: 148,
    iso2: 'MZ',
    iso3: 'MOZ',
    name: 'Mozambique',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇲🇲',
    id: 149,
    iso2: 'MM',
    iso3: 'MMR',
    name: 'Myanmar',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇳🇦',
    id: 150,
    iso2: 'NA',
    iso3: 'NAM',
    name: 'Namibia',
    postalTerm: 'Post Code',
  },
  {
    emoji: '🇳🇷',
    id: 151,
    iso2: 'NR',
    iso3: 'NRU',
    name: 'Nauru',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇳🇵',
    id: 152,
    iso2: 'NP',
    iso3: 'NPL',
    name: 'Nepal',
    type: t`Province`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇳🇱',
    id: 153,
    iso2: 'NL',
    iso3: 'NLD',
    name: 'Netherlands',
    type: t`Special Municipality`,
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇳🇨',
    id: 154,
    iso2: 'NC',
    iso3: 'NCL',
    name: 'New Caledonia',
    type: t`Province`,
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇳🇿',
    id: 155,
    iso2: 'NZ',
    iso3: 'NZL',
    name: 'New Zealand',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇳🇮',
    id: 156,
    iso2: 'NI',
    iso3: 'NIC',
    name: 'Nicaragua',
    type: t`Department`,
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇳🇪',
    id: 157,
    iso2: 'NE',
    iso3: 'NER',
    name: 'Niger',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇳🇬',
    id: 158,
    iso2: 'NG',
    iso3: 'NGA',
    name: 'Nigeria',
    type: t`State`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇳🇺',
    id: 159,
    iso2: 'NU',
    iso3: 'NIU',
    name: 'Niue',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇳🇫',
    id: 160,
    iso2: 'NF',
    iso3: 'NFK',
    name: 'Norfolk Island',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇰🇵',
    id: 161,
    iso2: 'KP',
    iso3: 'PRK',
    name: 'North Korea',
  },
  {
    emoji: '🇲🇰',
    id: 162,
    iso2: 'MK',
    iso3: 'MKD',
    name: 'North Macedonia',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇲🇵',
    id: 163,
    iso2: 'MP',
    iso3: 'MNP',
    name: 'Northern Mariana Islands',
    postalTerm: 'ZIP Code',
  },
  {
    emoji: '🇳🇴',
    id: 164,
    iso2: 'NO',
    iso3: 'NOR',
    name: 'Norway',
    type: t`County`,
    postalTerm: 'Postnummer',
  },
  {
    emoji: '🇴🇲',
    id: 165,
    iso2: 'OM',
    iso3: 'OMN',
    name: 'Oman',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇵🇰',
    id: 166,
    iso2: 'PK',
    iso3: 'PAK',
    name: 'Pakistan',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇵🇼',
    id: 167,
    iso2: 'PW',
    iso3: 'PLW',
    name: 'Palau',
    postalTerm: 'ZIP Code',
  },
  {
    emoji: '🇵🇸',
    id: 168,
    iso2: 'PS',
    iso3: 'PSE',
    name: 'Palestinian Territory Occupied',
    type: t`Governorate`,
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇵🇦',
    id: 169,
    iso2: 'PA',
    iso3: 'PAN',
    name: 'Panama',
  },
  {
    emoji: '🇵🇬',
    id: 170,
    iso2: 'PG',
    iso3: 'PNG',
    name: 'Papua New Guinea',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇵🇾',
    id: 171,
    iso2: 'PY',
    iso3: 'PRY',
    name: 'Paraguay',
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇵🇪',
    id: 172,
    iso2: 'PE',
    iso3: 'PER',
    name: 'Peru',
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇵🇭',
    id: 173,
    iso2: 'PH',
    iso3: 'PHL',
    name: 'Philippines',
    type: t`Province`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇵🇳',
    id: 174,
    iso2: 'PN',
    iso3: 'PCN',
    name: 'Pitcairn Island',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇵🇱',
    id: 175,
    iso2: 'PL',
    iso3: 'POL',
    name: 'Poland',
    type: t`Voivodship`,
    postalTerm: 'Kod Pocztowy',
  },
  {
    emoji: '🇵🇹',
    id: 176,
    iso2: 'PT',
    iso3: 'PRT',
    name: 'Portugal',
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇵🇷',
    id: 177,
    iso2: 'PR',
    iso3: 'PRI',
    name: 'Puerto Rico',
    type: t`Municipalities`,
    postalTerm: 'ZIP Code',
  },
  {
    emoji: '🇶🇦',
    id: 178,
    iso2: 'QA',
    iso3: 'QAT',
    name: 'Qatar',
  },
  {
    emoji: '🇷🇪',
    id: 179,
    iso2: 'RE',
    iso3: 'REU',
    name: 'Reunion',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇷🇴',
    id: 180,
    iso2: 'RO',
    iso3: 'ROU',
    name: 'Romania',
    postalTerm: 'Cod Poștal',
  },
  {
    emoji: '🇷🇺',
    id: 181,
    iso2: 'RU',
    iso3: 'RUS',
    name: 'Russia',
    postalTerm: 'Почтовый Индекс',
  },
  {
    emoji: '🇷🇼',
    id: 182,
    iso2: 'RW',
    iso3: 'RWA',
    name: 'Rwanda',
  },
  {
    emoji: '🇸🇭',
    id: 183,
    iso2: 'SH',
    iso3: 'SHN',
    name: 'Saint Helena',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇰🇳',
    id: 184,
    iso2: 'KN',
    iso3: 'KNA',
    name: 'Saint Kitts and Nevis',
  },
  {
    emoji: '🇱🇨',
    id: 185,
    iso2: 'LC',
    iso3: 'LCA',
    name: 'Saint Lucia',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇵🇲',
    id: 186,
    iso2: 'PM',
    iso3: 'SPM',
    name: 'Saint Pierre and Miquelon',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇻🇨',
    id: 187,
    iso2: 'VC',
    iso3: 'VCT',
    name: 'Saint Vincent and the Grenadines',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇧🇱',
    id: 188,
    iso2: 'BL',
    iso3: 'BLM',
    name: 'Saint-Barthelemy',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇲🇫',
    id: 189,
    iso2: 'MF',
    iso3: 'MAF',
    name: 'Saint-Martin (French part)',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇼🇸',
    id: 190,
    iso2: 'WS',
    iso3: 'WSM',
    name: 'Samoa',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇸🇲',
    id: 191,
    iso2: 'SM',
    iso3: 'SMR',
    name: 'San Marino',
    postalTerm: 'CPI',
  },
  {
    emoji: '🇸🇹',
    id: 192,
    iso2: 'ST',
    iso3: 'STP',
    name: 'Sao Tome and Principe',
  },
  {
    emoji: '🇸🇦',
    id: 193,
    iso2: 'SA',
    iso3: 'SAU',
    name: 'Saudi Arabia',
    type: t`Region`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇸🇳',
    id: 194,
    iso2: 'SN',
    iso3: 'SEN',
    name: 'Senegal',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇷🇸',
    id: 195,
    iso2: 'RS',
    iso3: 'SRB',
    name: 'Serbia',
    postalTerm: 'Poštanski Broj',
  },
  {
    emoji: '🇸🇨',
    id: 196,
    iso2: 'SC',
    iso3: 'SYC',
    name: 'Seychelles',
  },
  {
    emoji: '🇸🇱',
    id: 197,
    iso2: 'SL',
    iso3: 'SLE',
    name: 'Sierra Leone',
  },
  {
    emoji: '🇸🇬',
    id: 198,
    iso2: 'SG',
    iso3: 'SGP',
    name: 'Singapore',
    type: t`District`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇸🇽',
    id: 199,
    iso2: 'SX',
    iso3: 'SXM',
    name: 'Sint Maarten (Dutch part)',
  },
  {
    emoji: '🇸🇰',
    id: 200,
    iso2: 'SK',
    iso3: 'SVK',
    name: 'Slovakia',
    postalTerm: 'PSČ',
  },
  {
    emoji: '🇸🇮',
    id: 201,
    iso2: 'SI',
    iso3: 'SVN',
    name: 'Slovenia',
    postalTerm: 'Poštna Številka',
  },
  {
    emoji: '🇸🇧',
    id: 202,
    iso2: 'SB',
    iso3: 'SLB',
    name: 'Solomon Islands',
  },
  {
    emoji: '🇸🇴',
    id: 203,
    iso2: 'SO',
    iso3: 'SOM',
    name: 'Somalia',
  },
  {
    emoji: '🇿🇦',
    id: 204,
    iso2: 'ZA',
    iso3: 'ZAF',
    name: 'South Africa',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇬🇸',
    id: 205,
    iso2: 'GS',
    iso3: 'SGS',
    name: 'South Georgia',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇰🇷',
    id: 206,
    iso2: 'KR',
    iso3: 'KOR',
    name: 'South Korea',
    postalTerm: '우편번호',
  },
  {
    emoji: '🇸🇸',
    id: 207,
    iso2: 'SS',
    iso3: 'SSD',
    name: 'South Sudan',
  },
  {
    emoji: '🇪🇸',
    id: 208,
    iso2: 'ES',
    iso3: 'ESP',
    name: 'Spain',
    type: t`Province`,
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇱🇰',
    id: 209,
    iso2: 'LK',
    iso3: 'LKA',
    name: 'Sri Lanka',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇸🇩',
    id: 210,
    iso2: 'SD',
    iso3: 'SDN',
    name: 'Sudan',
  },
  {
    emoji: '🇸🇷',
    id: 211,
    iso2: 'SR',
    iso3: 'SUR',
    name: 'Suriname',
  },
  {
    emoji: '🇸🇯',
    id: 212,
    iso2: 'SJ',
    iso3: 'SJM',
    name: 'Svalbard and Jan Mayen Islands',
    postalTerm: 'Postnummer',
  },
  {
    emoji: '🇸🇪',
    id: 213,
    iso2: 'SE',
    iso3: 'SWE',
    name: 'Sweden',
    postalTerm: 'Postnummer',
  },
  {
    emoji: '🇨🇭',
    id: 214,
    iso2: 'CH',
    iso3: 'CHE',
    name: 'Switzerland',
    type: t`Canton`,
    postalTerm: 'Npa',
  },
  {
    emoji: '🇸🇾',
    id: 215,
    iso2: 'SY',
    iso3: 'SYR',
    name: 'Syria',
  },
  {
    emoji: '🇹🇼',
    id: 216,
    iso2: 'TW',
    iso3: 'TWN',
    name: 'Taiwan',
    type: t`County`,
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇹🇯',
    id: 217,
    iso2: 'TJ',
    iso3: 'TJK',
    name: 'Tajikistan',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇹🇿',
    id: 218,
    iso2: 'TZ',
    iso3: 'TZA',
    name: 'Tanzania',
    type: t`Region`,
    postalTerm: 'Postal Codes',
  },
  {
    emoji: '🇹🇭',
    id: 219,
    iso2: 'TH',
    iso3: 'THA',
    name: 'Thailand',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇧🇸',
    id: 220,
    iso2: 'BS',
    iso3: 'BHS',
    name: 'The Bahamas',
  },
  {
    emoji: '🇬🇲',
    id: 221,
    iso2: 'GM',
    iso3: 'GMB',
    name: 'The Gambia ',
  },
  {
    emoji: '🇹🇱',
    id: 222,
    iso2: 'TL',
    iso3: 'TLS',
    name: 'Timor-Leste',
  },
  {
    emoji: '🇹🇬',
    id: 223,
    iso2: 'TG',
    iso3: 'TGO',
    name: 'Togo',
  },
  {
    emoji: '🇹🇰',
    id: 224,
    iso2: 'TK',
    iso3: 'TKL',
    name: 'Tokelau',
  },
  {
    emoji: '🇹🇴',
    id: 225,
    iso2: 'TO',
    iso3: 'TON',
    name: 'Tonga',
  },
  {
    emoji: '🇹🇹',
    id: 226,
    iso2: 'TT',
    iso3: 'TTO',
    name: 'Trinidad and Tobago',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇹🇳',
    id: 227,
    iso2: 'TN',
    iso3: 'TUN',
    name: 'Tunisia',
    type: t`Governorate`,
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇹🇷',
    id: 228,
    iso2: 'TR',
    iso3: 'TUR',
    name: 'Turkey',
    type: t`Province`,
    postalTerm: 'Posta Kodu',
  },
  {
    emoji: '🇹🇲',
    id: 229,
    iso2: 'TM',
    iso3: 'TKM',
    name: 'Turkmenistan',
    postalTerm: 'Postal Code',
  },
  {
    emoji: '🇹🇨',
    id: 230,
    iso2: 'TC',
    iso3: 'TCA',
    name: 'Turks and Caicos Islands',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇹🇻',
    id: 231,
    iso2: 'TV',
    iso3: 'TUV',
    name: 'Tuvalu',
  },
  {
    emoji: '🇺🇬',
    id: 232,
    iso2: 'UG',
    iso3: 'UGA',
    name: 'Uganda',
    postalTerm: 'Postcodes',
  },
  {
    emoji: '🇺🇦',
    id: 233,
    iso2: 'UA',
    iso3: 'UKR',
    name: 'Ukraine',
    type: t`Republic`,
    postalTerm: 'Поштовий Індекс',
  },
  {
    emoji: '🇦🇪',
    id: 234,
    iso2: 'AE',
    iso3: 'ARE',
    name: 'United Arab Emirates',
  },
  {
    emoji: '🇬🇧',
    id: 235,
    iso2: 'GB',
    iso3: 'GBR',
    name: 'United Kingdom',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇺🇸',
    id: 236,
    iso2: 'US',
    iso3: 'USA',
    name: 'United States',
    type: t`State`,
    postalTerm: 'ZIP Code',
  },
  {
    emoji: '🇺🇲',
    id: 237,
    iso2: 'UM',
    iso3: 'UMI',
    name: 'United States Minor Outlying Islands',
    type: t`Island`,
    postalTerm: 'ZIP Code',
  },
  {
    emoji: '🇺🇾',
    id: 238,
    iso2: 'UY',
    iso3: 'URY',
    name: 'Uruguay',
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇺🇿',
    id: 239,
    iso2: 'UZ',
    iso3: 'UZB',
    name: 'Uzbekistan',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇻🇺',
    id: 240,
    iso2: 'VU',
    iso3: 'VUT',
    name: 'Vanuatu',
  },
  {
    emoji: '🇻🇦',
    id: 241,
    iso2: 'VA',
    iso3: 'VAT',
    name: 'Vatican City State (Holy See)',
    postalTerm: 'CAP',
  },
  {
    emoji: '🇻🇪',
    id: 242,
    iso2: 'VE',
    iso3: 'VEN',
    name: 'Venezuela',
    type: t`State`,
    postalTerm: 'Código Postal',
  },
  {
    emoji: '🇻🇳',
    id: 243,
    iso2: 'VN',
    iso3: 'VNM',
    name: 'Vietnam',
    postalTerm: 'Mã Bưu Chính',
  },
  {
    emoji: '🇻🇬',
    id: 244,
    iso2: 'VG',
    iso3: 'VGB',
    name: 'Virgin Islands (British)',
    postalTerm: 'Postcode',
  },
  {
    emoji: '🇻🇮',
    id: 245,
    iso2: 'VI',
    iso3: 'VIR',
    name: 'Virgin Islands (US)',
    type: t`District`,
    postalTerm: 'ZIP Code',
  },
  {
    emoji: '🇼🇫',
    id: 246,
    iso2: 'WF',
    iso3: 'WLF',
    name: 'Wallis and Futuna Islands',
    postalTerm: 'Code Postal',
  },
  {
    emoji: '🇪🇭',
    id: 247,
    iso2: 'EH',
    iso3: 'ESH',
    name: 'Western Sahara',
  },
  {
    emoji: '🇾🇪',
    id: 248,
    iso2: 'YE',
    iso3: 'YEM',
    name: 'Yemen',
  },
  {
    emoji: '🇿🇲',
    id: 249,
    iso2: 'ZM',
    iso3: 'ZMB',
    name: 'Zambia',
  },
  {
    emoji: '🇿🇼',
    id: 250,
    iso2: 'ZW',
    iso3: 'ZWE',
    name: 'Zimbabwe',
  },
];

export default countries;
