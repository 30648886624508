import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { listCmsEvents as listEventsFn } from 'common/services/events/api';

import { PENDING } from '@components/DomainStatusTag';

import {
  list as listFn,
  get as getFn,
  create as createFn,
  verify as verifyFn,
  remove as removeFn,
  linkEvents as linkEventsFn,
} from './service';

const VERIFY_CUSTOM_DOMAIN_INTERVAL = 10 * 1000;
export const INVALID_PUBLIC_DOMAIN_FAILURE_REASON = 'INVALID_PUBLIC_DOMAIN';

const listQueryOptions = (organizationId, params, enabled) => ({
  queryKey: ['organization', organizationId, 'custom-domain'].concat(params ?? []),
  queryFn: () => listFn(organizationId, params),
  enabled,
});

const eventsQueryOptions = organizationId => ({
  queryKey: ['organization', organizationId, 'events'],
  queryFn: () => listEventsFn(organizationId),
  select: ({ data }) => data,
});

export const useList = (organizationId, params, enabled) =>
  useQuery(listQueryOptions(organizationId, params, enabled));

export const useEvents = organizationId => useQuery(eventsQueryOptions(organizationId));

export const useCreateCustomDomainMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['custom-domain', 'create'],
    mutationFn: ({ organizationId, payload }) => createFn(organizationId, payload),
    onSuccess: (data, { organizationId }) => {
      queryClient.invalidateQueries({ queryKey: listQueryOptions(organizationId).queryKey });
    },
  });
};

export const useVerifyCustomDomain = (organizationId, id, regenerateMode) =>
  useQuery({
    queryKey: ['custom-domain', 'verify'],
    queryFn: () => verifyFn(organizationId, id),
    refetchInterval: prev => {
      if (regenerateMode) {
        return prev?.data.verificationStatus !== PENDING ? VERIFY_CUSTOM_DOMAIN_INTERVAL : false;
      }
      return (prev?.data.dnsRecords.length === 0 || prev?.data.verificationStatus !== PENDING) &&
        prev?.data.failureReason !== INVALID_PUBLIC_DOMAIN_FAILURE_REASON
        ? VERIFY_CUSTOM_DOMAIN_INTERVAL
        : false;
    },
    enabled: !!id,
    cacheTime: 0,
  });

export const useRemoveCustomDomainMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['custom-domain', 'remove'],
    mutationFn: ({ organizationId, ids }) =>
      Promise.all(ids.map(id => removeFn(organizationId, id))),
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries({ queryKey: listQueryOptions(organizationId).queryKey });
    },
  });
};

export const useLinkEventsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['custom-domain', 'link-events'],
    mutationFn: ({ organizationId, id, linkedEvents, rootDomainRedirectUrl = undefined }) =>
      linkEventsFn(organizationId, id, linkedEvents, rootDomainRedirectUrl),
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries({ queryKey: listQueryOptions(organizationId).queryKey });
    },
  });
};

export const get = {
  getQueryKey: (organizationId, id) => ['organization', organizationId, 'customDomains', id],
  queryFn: ({ queryKey: [, organizationId, , id] }) => getFn(organizationId, id),
};

export const useCustomDomain = (organizationId, id) =>
  useQuery({
    queryKey: get.getQueryKey(organizationId, id),
    queryFn: get.queryFn,
    enabled: !!id,
  });
